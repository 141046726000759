import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { USER_SEND_PASSWORD_RESET_WITH_TOKEN } from '../Graphql/Mutations'
import { useMutation } from '@apollo/client'
import sideImage from '../Images/Homeprint-login.jpeg'
import handle_errors from '../Helpers/auth_errors'


function ForgotPassword() {
    /* 
       Pass the useFormik() hook initial form values and a submit function that will
       be called when the form is submitted
    */
    const formik = useFormik({
        initialValues:{
            emailAddress:'',           
        },
        validationSchema: Yup.object({
            emailAddress: Yup.string().required('Required'),
        }),
        onSubmit:values => {
            alert(JSON.stringify(values, null, 2))
        },
    })

    // useMutation hook. TODO: update with correct mutation
    const [sendPasswordResetWithToken, { data, loading, error }] = useMutation(USER_SEND_PASSWORD_RESET_WITH_TOKEN)

    // Handles the submision of the form field and sends request to update password to server
    function handleSubmit(e) {
        e.preventDefault()
        // TODO:Update with the correct mutation
        // console.log(formik.values.emailAddress)
        sendPasswordResetWithToken({
            variables: {
                email: formik.values.emailAddress,
            },
            onCompleted: (data) => {
                alert(data.userSendPasswordResetWithToken.message)
            },
            onError: (errors) => {
                // setErrors(true)
                // console.log(errors)
            }
        })
        if (data) {
            // console.log("data: "+ data)
        }
        if (loading) {
            // console.log("loading: "+loading)
        }
        if (error) {
            // console.log("error: "+error)
        }
    }
    return (
        <>
            {/* wrapper */}
            <div className="font-int flex  h-screen flex-col bg-white lg:relative">
                {/* container of body and image */}
                <div className="flex max-w-full flex-grow flex-col md:flex-row">
                    {/* container for vertical alignment and large screens in 2 */}
                    <div className="md:w-1/2 md:self-center">
                        {/* container for horizontal alignment of text elements */}
                        <div className="mx-auto max-w-sm px-3 py-12 sm:pt-12 sm:pb-12 lg:py-16 2xl:max-w-lg">
                            {/* title */}
                            <h1 className="my-6 text-4xl font-bold tracking-tight text-gray-900 text-center sm:text-5xl sm:tracking-tight">
                                Forgot password
                            </h1>
                            <p className="text-justify text-base font-normal text-[#667085]
                                        mb-4
                            "> 
                                Type in your email and we will send you a link to reset your password. 
                            </p>
                            <form
                                className="max-w-sm md:max-w-md 2xl:max-w-lg"
                                onSubmit={handleSubmit}
                            >
                                {/* email address */}
                                <div className="mx-auto flex flex-col py-2">
                                    <label
                                        htmlFor="emailAddress"
                                        className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                    >
                                        Email*
                                    </label>
                                    <input
                                        type="text"
                                        name="emailAddress"
                                        id="emailAddress"
                                        className="border-blue-gray-300 col-span-2 inline-block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm 2xl:text-base"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.emailAddress}
                                    />
                                    {formik.touched.emailAddress && formik.errors.emailAddress ? (
                                    <div className='mt-1 text-xs text-red-800'> {formik.errors.emailAddress} </div>
                                    ): null }
                                </div>
                                {handle_errors(error)}
                                {/* Button's container */}
                                <div className="mx-auto mt-4 flex flex-col md:max-w-sm 2xl:max-w-lg">
                                    {/* 'Send email' button */}
                                    <button
                                        onClick={() => null
                                        }
                                        className="mb-3 btn-primary-lg 2xl:text-lg"
                                    >
                                        Send email
                                    </button>
                                    {/* login button redirects to Login component */}
                                    <p className="text-center">
                                        Go back to
                                        <a
                                            className="ml-1 text-[#6941C6] hover:cursor-pointer"
                                            href="/login"
                                        >
                                            Log in
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* photo */}
                    <div className="hidden md:flex md:w-1/2">
                        <img
                            className="object-cover md:object-right"
                            src={sideImage}
                            alt="House at dusk, with warm lighting seen inside"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword