import { gql } from '@apollo/client'

// define mutation for sign up
export const USER_SIGN_UP = gql`
    mutation userRegister(
        $firstName: String!
        $lastName: String!
        $agent: Boolean
        $email: String!
        $phoneNumber: String
        $password: String!
        $passwordConfirmation: String!
        $confirmSuccessUrl: String!
    ) {
        userRegister(
            firstName: $firstName
            lastName: $lastName
            agent: $agent
            email: $email
            phoneNumber: $phoneNumber
            password: $password
            passwordConfirmation: $passwordConfirmation
            confirmUrl: $confirmSuccessUrl
        ) {
            #This is what is returned after the mutation happens
            authenticatable {
                firstName
                lastName
                email
                phoneNumber
                id
            }
            credentials {
                accessToken
                client
                expiry
                tokenType
                uid
            }
        }
    }
`
// define mutation to login
export const USER_LOGIN = gql`
    mutation userLogin($email: String!, $password: String!) {
        userLogin(email: $email, password: $password) {
            #This is what is returned after the mutation happens
            authenticatable {
                email
                firstName
                phoneNumber
                id
                lastName
            }
            credentials {
                accessToken
                client
                expiry
                tokenType
                uid
            }
        }
    }
`

// define mutation to update the user's information
export const USER_UPDATE = gql`
    mutation userUpdate(
        $apiKey: String!
        $firstName: String
        $lastName: String
        $email: String
        $phoneNumber: String
        $bio: String
    ) {
        userUpdate(
            input: {
                apiKey: $apiKey
                firstName: $firstName
                lastName: $lastName
                email: $email
                phoneNumber: $phoneNumber
                bio: $bio
            }
        ) {
            user {
                id
                email
                phoneNumber
            }
            errors
        }
    }
`
// define Mutation for User Confirmation Token. Used to confirm user's email at Confirm.js
export const USER_CONFIRM_ACCOUNT = gql`
    mutation userConfirmAccount($apiKey: String!, $token: String!) {
        userConfirmAccount(input: { apiKey: $apiKey, token: $token }) {
            errors
            success
        }
    }
`
// define Mutation for Property Search. Used to pre-populate fields at onboarding.
export const PROPERTY_SEARCH = gql`
    mutation propertySearch(
        $apiKey: String!
        $address: String!
        $isCondo: Boolean
        $lat: Float!
        $lng: Float!
        $streetNum: String!
        $streetName: String!
        $streetSuffix: String!
        $city: String!
        $province: String!
        $postalCode: String!
        $country: String!
        $unitNum: String
        $dev: Boolean
    ) {
        propertySearch(
            input: {
                apiKey: $apiKey
                address: $address
                isCondo: $isCondo
                lat: $lat
                lng: $lng
                streetNum: $streetNum
                streetName: $streetName
                streetSuffix: $streetSuffix
                city: $city
                province: $province
                postalCode: $postalCode
                country: $country
                unitNum: $unitNum
                dev: $dev
            }
        ) {
            errors
            property {
                fullAddress
                streetNo
                neighbourhood
                city
                province
                coordinates {
                    lat
                    lng
                }
                postalCode
                tempPostalCode
                acres
                id
                isCondo
                landDepth
                landFrontage
                landUnit
                addressId
                desc
                propertyBuildings{
                    id
                    architecturalStyle
                    attachment
                    buildingType
                    styleOfDwelling
                    typeOfDwelling
                    frontDirection
                    yearBuilt
                    ageStatus
                    sqftTotal
                    totalFloorAreaUnit
                    totalFloorArea
                    storeys
                    amenities
                    bedroomsAbove
                    bedroomsBelow
                    constructionType
                    hasBasement
                    basementType
                    basementFinishType
                    basementDesc
                    features
                    buildingAmenities
                    occupancyType
                    bathroomsTotal
                    fullBathrooms
                    mainPhoto {
                        id
                        fileName
                    }
                    additionalPhotos {
                        id
                        fileName
                    }
                    partialBathrooms
                    bedroomsTotal
                    status
                    propertyBuildingParking {
                        additionalParking
                        age
                        colourCode
                        colourName
                        condition
                        conditionRating
                        description
                        drivewayMaterial
                        drivewaySpaces
                        drivewayWidth
                        garageFeatures
                        garageFloors
                        garageSpaces
                        garageType
                        id
                        insideEntry
                        instillationDate
                        manufacturer
                        purchaseDate
                        purchasePrice
                        status
                        serviceDate
                        warrantyExpiration
                        storage
                        warrantyTerm
                      }
                    propertyBuildingAreas {
                        id
                        areaType
                        roomLevel
                        status
                        intExt
                    }
                }
                propertyType
                propertyTaxInfo{
                    id
                    desc
                    zoningDesc
                    assesmentRoll
                    zoningCode
                    updatedAt
                    createdAt
                    amount
                    assesmentAmount
                }
                propertyTransactions {
                    id
                    purchaseDate
                    purchasePrice
                }
                onboarded
            }
        }
    }
`
// TODO: add a 'hasBasement' input variable when available in propertyUpdate mutation in live
// define Mutation for Property Overview Update. Used to save home style, home type, year built, lot size, acres, size, and basement
export const PROPERTY_BUILDING_UPDATE_ONBOARDING_OVERVIEW = gql`
    mutation propertyBuildingUpdate(
        $apiKey: String!
        $propertyBuildingId: ID!

        $buildingType: String
        $architecturalStyle: String
        $attachment: String
        
        $styleOfDwelling: String
        $typeOfDwelling: String
        $frontDirection: String
        $yearBuilt: String
        $ageStatus: String
        $sqftTotal: Float
        $totalFloorArea: Float
        $totalFloorAreaUnit: String

        $bedroomsAbove: String
        $bedroomsBelow: String
        $bedroomsTotal: String

        $partialBathrooms: String
        $fullBathrooms: String
        $bathroomsTotal: String

        $hasBasement: Boolean
        $basementType: String
        $constructionType: String
        $basementFinishType: String
        $basementDesc: String
        $features: [String!]
        $occupancyType: String
        $status: String
        $usage: String
        $storeys: Float
        $buildingAmenities: [String!]

        $garageType: String, 
        $garageSpaces: String, 
        $garageFloors: String, 
        $garageFeatures: [String!], 
        $drivewaySpaces: String, 
        $drivewayWidth: String, 
        $drivewayMaterial: String
    ) {
        propertyBuildingUpdate(
            input: {
                apiKey: $apiKey
                propertyBuildingId: $propertyBuildingId

                buildingType: $buildingType
                architecturalStyle: $architecturalStyle
                attachment: $attachment

                styleOfDwelling: $styleOfDwelling
                typeOfDwelling: $typeOfDwelling
                frontDirection: $frontDirection,
                yearBuilt: $yearBuilt,
                ageStatus: $ageStatus,
                sqftTotal: $sqftTotal,
                totalFloorArea: $totalFloorArea,
                totalFloorAreaUnit: $totalFloorAreaUnit,

                bedroomsAbove: $bedroomsAbove,
                bedroomsBelow: $bedroomsBelow,
                bedroomsTotal: $bedroomsTotal,

                partialBathrooms: $partialBathrooms,
                fullBathrooms: $fullBathrooms,
                bathroomsTotal: $bathroomsTotal,

                hasBasement: $hasBasement,
                basementType: $basementType,
                constructionType: $constructionType,
                basementFinishType: $basementFinishType,
                basementDesc: $basementDesc,
                features: $features,
                occupancyType: $occupancyType,
                status: $status,
                usage: $usage,
                storeys: $storeys,
                buildingAmenities: $buildingAmenities,

                garageType: $garageType, 
                garageSpaces: $garageSpaces, 
                garageFloors: $garageFloors, 
                garageFeatures: $garageFeatures, 
                drivewaySpaces: $drivewaySpaces, 
                drivewayWidth: $drivewayWidth, 
                drivewayMaterial: $drivewayMaterial
            }
        ) {
            errors
            propertyBuilding {
                id

                architecturalStyle
                attachment
                buildingType
                
                styleOfDwelling
                typeOfDwelling
                frontDirection
                yearBuilt
                ageStatus
                sqftTotal
                totalFloorArea
                totalFloorAreaUnit
                bedroomsAbove
                bedroomsBelow
                constructionType
                hasBasement
                basementType
                basementFinishType
                basementDesc
                mainPhoto {
                    id
                    fileName
                }
                storeys
                additionalPhotos {
                    id
                    fileName
                }
                features
                buildingAmenities
                occupancyType
                bathroomsTotal
                fullBathrooms
                partialBathrooms
                bedroomsTotal
                status
                propertyBuildingAreas {
                    areaType
                    roomLevel
                    id
                    intExt
                    propertyBuildingAreaProjects{
                        id
                        projectType
                        finishDate
                        totalCosts
                    }
                }
            }
        }
    }
`

export const PROPERTY_UPDATE_BASEMENT_ONBOARDING_OVERVIEW = gql`
    mutation propertyBuildingUpdate(
        $apiKey: String!
        $propertyBuildingId: ID

        $architecturalStyle: String!
        $attachment: String!
        $buildingType: String!

        $styleOfDwelling: String!
        $typeOfDwelling: String!
        $yearBuilt: String!
        $sqftTotal: Float!
        $totalFloorArea: Float
        $totalFloorAreaUnit: String
    ) {
        propertyBuildingUpdate(
            input: {
                apiKey: $apiKey
                propertyBuildingId: $propertyBuildingId

                architecturalStyle: $architecturalStyle
                attachment: $attachment
                buildingType: $buildingType

                styleOfDwelling: $styleOfDwelling
                typeOfDwelling: $typeOfDwelling
                yearBuilt: $yearBuilt
                sqftTotal: $sqftTotal
                totalFloorArea: $totalFloorArea
                totalFloorAreaUnit: $totalFloorAreaUnit
            }
        ) {
            errors
            propertyBuilding {
                id

                architecturalStyle
                attachment
                buildingType

                bedroomsAbove
                bedroomsBelow
                fullBathrooms
                bathroomsTotal
                bedroomsTotal
                partialBathrooms
                propertyBuildingAreas {
                    areaType
                    roomLevel
                    id
                    intExt
                }
            }
        }
    }
    mutation propertyBuildingAreaCreate(
        $apiKey: String!
        $areaType: String!
        # TODO: rename to interiorExterior
        $interior: String!
        $roomLevel: Float!
        $status: String!
    ) {
        propertyBuildingAreaCreate(
            input: {
                apiKey: $apiKey
                areaType: $areaType
                # TODO: rename to interiorExterior
                interior: $interior
                roomLevel: $roomLevel
                status: $status
            }
        ) {
            errors
            propertyBuilding {
                id
                propertyBuildingAreas {
                id
                areaType
                roomLevel
                intExt
                }
            }
        }
    }
`

// define Mutation for Creating an Area
//  TODO: rename to interior to interiorExterior in all places (ctrl + f)
export const PROPERTY_BUILDING_AREA_CREATE = gql`
    mutation propertyBuildingAreaCreate(
        $apiKey: String!
        $areaType: String!
        $propertyBuildingId: ID!
        # TODO: rename to interiorExterior
        # $interior: String!
        $roomLevel: Float!
        $status: String!
    ) {
        propertyBuildingAreaCreate(
            input: {
                apiKey: $apiKey
                areaType: $areaType
                propertyBuildingId: $propertyBuildingId
                # TODO: rename to interiorExterior
                # interior: $interior
                roomLevel: $roomLevel
                status: $status
            }
        ) {
            errors
            propertyBuildingArea {
                id
                areaType
                roomLevel
                intExt
                propertyBuildingAreaProjects {
                    id
                    projectType
                    finishDate
                    totalCosts
                }
            }
        }
    }
`

export const PROPERTY_BUILDING_AREA_DELETE = gql`
    mutation propertyBuildingAreaDelete(
        $apiKey: String!
        $propertyBuildingAreaId: ID!
    ) {
        propertyBuildingAreaDelete(
            input: {
                apiKey: $apiKey, 
                propertyBuildingAreaId: $propertyBuildingAreaId
            }
        ) {
            errors
            success
        }
    }
`

// Mutation for creating a Project, which is a child of an Area
// export const PROPERTY_AREA_PROJECT_CREATE = gql`

// `

// Mutation for creating Goals for the property
// export const PROPERTY_GOAL_CREATE = gql`

// `

// Mutation for creating updates for the property
export const PROPERTY_UPDATES_CREATE = gql`
    mutation propertyUpdatesCreate(
        $apiKey: String!
        $propertyId: ID!
        $remodelingUpdates: [String!]!
        $efficiencyUpdates: [String!]!
    ) {
        propertyUpdatesCreate(
            input: {
                apiKey: $apiKey
                propertyId: $propertyId
                remodelingUpdates: $remodelingUpdates
                efficiencyUpdates: $efficiencyUpdates
            }
        ) {
            errors
            property {
              id
              propertyUpdates {
                efficiencyUpdates
                remodelingUpdates
                id
              }
            }
        }
    }
`

// Mutation for creating goals for the property
export const PROPERTY_GOALS_CREATE = gql`
    mutation propertyGoalCreate(
        $apiKey: String!
        $propertyId: ID!
        $goals: [String!]!
    ) {
        propertyGoalCreate(
            input: { apiKey: $apiKey, propertyId: $propertyId, goals: $goals }
        ) {
            errors
            property {
                id
                propertyGoals {
                    goal
                    id
                }
            }
        }
    }
`

// Mutation for sending email with password reset token
export const USER_SEND_PASSWORD_RESET_WITH_TOKEN = gql`
    mutation userSendPasswordResetWithToken(
        $email: String!
    ) {
        userSendPasswordResetWithToken(email: $email, redirectUrl: "homeprint.io") {
            message
        }
    }
`

// Mutation for resetting a users password given a valid token
export const USER_UPDATE_PASSWORD_WITH_TOKEN = gql`
    mutation userUpdatePasswordWithToken(
        $password: String!
        $confirm_password: String!
        $token: String!
        ){
        userUpdatePasswordWithToken(
            password: $password,
            passwordConfirmation: $confirm_password,
            resetPasswordToken: $token
        ) {
            credentials {
                accessToken
                client
                expiry
                tokenType
                uid
            }
            authenticatable {
                email
                phoneNumber
                firstName
                id
                lastName
            }
        }
    }
`

export const PROPERTY_BUILDING_AREA_CREATE_MANY = gql`
    mutation propertyBuildingAreaCreateMany(
        $apiKey: String!
        $propertyBuildingId: ID!
        $areaTypes: [String!]!
        $counts: [String!]!
        $intExts: [String!]!
    ){
    propertyBuildingAreaCreateMany(
      input: {
              apiKey: $apiKey, 
              propertyBuildingId: $propertyBuildingId, 
              areaTypes: $areaTypes, 
              counts: $counts,
              intExts: $intExts
        }
    ) {
        errors
        propertyBuilding {
            id
            bedroomsAbove
            bedroomsBelow
            bathroomsTotal
            bedroomsTotal
            fullBathrooms
            partialBathrooms
            hasBasement
            propertyBuildingAreas {
                areaType
                roomLevel
                id
                intExt
                propertyBuildingAreaProjects{
                    id
                    projectType
                    finishDate
                    totalCosts
                }
            }
        }
    }
}
`

export const PROPERTY_BUILDING_AREA_PROJECT_CREATE_MANY = gql`
    mutation propertyBuildingAreaProjectCreateMany(
        $apiKey: String!
        $propertyBuildingAreaIds: [ID!]!
        $budgets: [String!]!
        $finishDates: [String!]!
        $startDates: [String!]!
        $statuses: [String!]!
        $totalCosts: [String!]!
        $projectTypes: [String!]!
    ){
        propertyBuildingAreaProjectCreateMany(
            input: {
                apiKey: $apiKey,
                propertyBuildingAreaIds: $propertyBuildingAreaIds,
                budgets: $budgets,
                finishDates: $finishDates,
                startDates: $startDates,
                statuses: $statuses,
                totalCosts: $totalCosts,
                projectTypes: $projectTypes
            }
        ) {
            errors
            property {
                propertyBuildings {
                    id
                    propertyBuildingAreas {
                      areaType
                      id
                      propertyBuildingAreaProjects {
                        finishDate
                        id
                        projectType
                        startDate
                        totalCosts
                      }
                      intExt
                      roomLevel
                      status
                    }
                    additionalPhotos {
                      id
                      name
                      filePath
                      fileName
                      contentType
                    }
                    ageStatus
                    basementDesc
                    basementFinishType
                    basementType
                    bathroomsTotal
                    bedroomsAbove
                    bedroomsBelow
                    bedroomsTotal
                    buildingAmenities
                    constructionType
                    features
                    frontDirection
                    fullBathrooms
                    hasBasement
                    mainPhoto {
                      contentType
                      fileName
                      filePath
                      id
                      name
                    }
                    occupancyType
                    partialBathrooms
                    propertyBuildingParking {
                      id
                    }
                    sqftTotal
                    totalFloorArea
                    totalFloorAreaUnit
                    status
                    storeys
                    styleOfDwelling
                    typeOfDwelling
                    yearBuilt
                }
            }
        }
    }
`

export const PROPERTY_USAGE_CREATE = gql`
    mutation propertyUsageCreate(
            $apiKey: String!
            $propertyId: ID!
            $usageDesc: String!
            $ownershipStatus: String!
        ){
        propertyUsageCreate(
            input: {
                apiKey: $apiKey, 
                propertyId: $propertyId, 
                usageDesc: $usageDesc, 
                ownershipStatus: $ownershipStatus
            }
        )   {
            errors
            property {
                id
                propertyUsage {
                    id
                    ownershipStatus
                    usageDesc
                }
            }
        }
    }
`
export const PROPERTY_USAGE_UPDATE = gql`
    mutation propertyUsageUpdate(
        $apiKey: String!
        $usageId: ID!
        $usageDesc: String!
        $ownershipStatus: String!
    ){
        propertyUsageUpdate(
            input: {
                apiKey: $apiKey
                usageId: $usageId
                usageDesc: $usageDesc
                ownershipStatus: $ownershipStatus
            }
        ){
            propertyUsage {
                id
                ownershipStatus
                usageDesc
            }
            errors
        }
    }
`

export const PROPERTY_TRANSACTION_CREATE = gql`
    mutation propertyTransactionCreate(
        $apiKey: String!
        $propertyId: ID!
        $purchaseDate: String!
        $purchasePrice: Float!
    ){
        propertyTransactionCreate(
            input: {
                apiKey: $apiKey, 
                propertyId: $propertyId, 
                purchaseDate: $purchaseDate, 
                purchasePrice: $purchasePrice
            }
        ) {
            property {
                id
                propertyTransactions {
                    id
                    purchaseDate
                    purchasePrice
                }
            }
            errors
        }
    }
`

export const PROPERTY_TRANSACTION_UPDATE = gql`
    mutation propertyTransactionUpdate(
        $apiKey: String!
        $transactionId: ID!
        $purchaseDate: String!
        $purchasePrice: Float
    ){
        propertyTransactionUpdate(
            input: {
                apiKey: $apiKey, 
                transactionId: $transactionId, 
                purchaseDate: $purchaseDate, 
                purchasePrice: $purchasePrice
            }
        ) {
            propertyTransaction {
                id
                purchaseDate
                purchasePrice
            }
            errors
        }
    }
`


export const PROPERTY_DOCUMENT_CREATE = gql`
    mutation propertyDocumentCreate(
        $apiKey: String!, 
        $propertyId: ID!, 
        $documentType: String!, 
        $category: String!, 
        $name: String!, 
        $propertyBuildingAreaId: ID, 
        $propertyBuildingAreaProjectIds: [ID!], 
        $desc: String!, 
        $expiry: String!, 
        $year: String!
    ){
        propertyDocumentCreate(
        input: {
            apiKey: $apiKey, 
            propertyId: $propertyId, 
            documentType: $documentType, 
            category: $category, 
            name: $name, 
            propertyBuildingAreaId: $propertyBuildingAreaId, 
            propertyBuildingAreaProjectIds: $propertyBuildingAreaProjectIds, 
            desc: $desc, 
            expiry: $expiry, 
            year: $year
        }
        ) {
            document {
                id
                name
                file {
                    id
                    fileName
                }
                year
                expiry
                documentType
                propertyBuildingAreaId
                propertyBuildingAreaProjects{
                    id
                    projectType
                }
                createdAt
                updatedAt
                desc
                category
            }
            errors
        }
    }
`

export const PROPERTY_DOCUMENT_UPDATE = gql`
    mutation propertyDocumentUpdate(
        $apiKey: String!, 
        $documentId: ID!, 
        $documentType: String!, 
        $category: String!, 
        $name: String!, 
        $propertyBuildingAreaId: ID, 
        $propertyBuildingAreaProjectIds: [ID!],
        $desc: String!, 
        $expiry: String!, 
        $year: String!
    ){
        propertyDocumentUpdate(
        input: {
            apiKey: $apiKey, 
            documentId: $documentId, 
            documentType: $documentType, 
            category: $category, 
            name: $name, 
            propertyBuildingAreaId: $propertyBuildingAreaId, 
            propertyBuildingAreaProjectIds: $propertyBuildingAreaProjectIds, 
            desc: $desc, 
            expiry: $expiry, 
            year: $year
        }
        ) {
            document {
                id
                name
                file {
                    id
                    fileName
                }
                year
                expiry
                documentType
                propertyBuildingAreaId
                propertyBuildingAreaProjects{
                    id
                    projectType
                }
                createdAt
                updatedAt
                desc
                category
            }
            errors
        }
    }
`

export const PROPERTY_DOCUMENT_DELETE = gql`
    mutation propertyDocumentCreate(
        $apiKey: String!, 
        $documentId: ID!
    ){
        propertyDocumentDelete(input: {
            apiKey: $apiKey,
            documentId: $documentId,
            }
        ) {
            success
        }
    }
`

export const PROPERTY_INSURANCE_DELETE = gql`
  mutation propertyInsuranceDelete(
    $apiKey: String!, 
    $insuranceId: ID!
  ){
    propertyInsuranceDelete(
      input: {
        apiKey: $apiKey, 
        insuranceId: $insuranceId
      }
    ) {
      errors
      success
    }
  }
  
`

export const PROPERTY_INSURANCE_CREATE = gql`
    mutation propertyInsuranceCreate(
        $apiKey: String!,
        $propertyId: ID!,
        $insuranceType: String,
        $companyPhone: String,
        $companyName: String,
        $companyTollFreePhone: String,
        $companyClaimsPhone: String,
        $companyAddress: String,
        $companyEmail: String,
        $policyNum: String,
        $annualPolicyCost: Float,
        $paymentFrequency: String,
        $paymentAmount: Float,
        $coverageAmount: Float,
        $deductibleAmount: Float,
        $costOfRebuild: Float,
        $coverageStartDate: String,
        $policyTerm: String,
        $advisorTollFreePhone: String,
        $notes: String,
        $comprehensiveWaterForgiveness: Boolean,
        $renewalDate: String,
        $personalPropertyCoverage: Float,
        $inclusions: String,
        $exclusions: String,
        $comprehensiveWater: Boolean,
        $discounts: [String!],
        $advisorName: String,
        $advisorPhone: String,
        $advisorEmail: String,
        $advisorAddress: String,
        $advisorWebsite: String,
        $advisorCompanyName: String,
        $documents: [ID!],
    ){
        propertyInsuranceCreate(
            input: {
                apiKey: $apiKey,
                propertyId: $propertyId,
                insuranceType: $insuranceType,
                companyPhone: $companyPhone,
                companyName: $companyName,
                companyTollFreePhone: $companyTollFreePhone,
                companyClaimsPhone: $companyClaimsPhone,
                companyAddress: $companyAddress,
                companyEmail: $companyEmail,
                policyNum: $policyNum,
                annualPolicyCost: $annualPolicyCost,
                paymentFrequency: $paymentFrequency,
                paymentAmount: $paymentAmount,
                coverageAmount: $coverageAmount,
                deductibleAmount: $deductibleAmount,
                costOfRebuild: $costOfRebuild,
                coverageStartDate: $coverageStartDate,
                policyTerm: $policyTerm,
                advisorTollFreePhone: $advisorTollFreePhone,
                notes: $notes,
                comprehensiveWaterForgiveness: $comprehensiveWaterForgiveness,
                renewalDate: $renewalDate,
                personalPropertyCoverage: $personalPropertyCoverage,
                inclusions: $inclusions,
                exclusions: $exclusions,
                comprehensiveWater: $comprehensiveWater,
                discounts: $discounts,
                advisorName: $advisorName,
                advisorPhone: $advisorPhone,
                advisorEmail: $advisorEmail,
                advisorAddress: $advisorAddress,
                advisorWebsite: $advisorWebsite,
                advisorCompanyName: $advisorCompanyName,
                documents: $documents
            }
        ) {
            errors
            property {
                propertyInsurances{
                    id
                }
                id
            }
        }
    }
`

export const PROPERTY_INSURANCE_UPDATE = gql`
    mutation propertyInsuranceUpdate(
        $apiKey: String!,
        $insuranceId: ID!,
        $insuranceType: String,
        $companyPhone: String,
        $companyName: String,
        $companyTollFreePhone: String,
        $companyClaimsPhone: String,
        $companyAddress: String,
        $companyEmail: String,
        $policyNum: String,
        $annualPolicyCost: Float,
        $paymentFrequency: String,
        $paymentAmount: Float,
        $coverageAmount: Float,
        $deductibleAmount: Float,
        $costOfRebuild: Float,
        $coverageStartDate: String,
        $policyTerm: String,
        $advisorTollFreePhone: String,
        $notes: String,
        $comprehensiveWaterForgiveness: Boolean,
        $documents: [ID!],
        $renewalDate: String,
        $personalPropertyCoverage: Float,
        $inclusions: String,
        $exclusions: String,
        $comprehensiveWater: Boolean,
        $discounts: [String!],
        $advisorName: String,
        $advisorPhone: String,
        $advisorEmail: String,
        $advisorAddress: String,
        $advisorWebsite: String,
        $advisorCompanyName: String
    ){
        propertyInsuranceUpdate(
            input: {
                apiKey: $apiKey,
                insuranceId: $insuranceId,
                insuranceType: $insuranceType,
                companyPhone: $companyPhone,
                companyName: $companyName,
                companyTollFreePhone: $companyTollFreePhone,
                companyClaimsPhone: $companyClaimsPhone,
                companyAddress: $companyAddress,
                companyEmail: $companyEmail,
                policyNum: $policyNum,
                annualPolicyCost: $annualPolicyCost,
                paymentFrequency: $paymentFrequency,
                paymentAmount: $paymentAmount,
                coverageAmount: $coverageAmount,
                deductibleAmount: $deductibleAmount,
                costOfRebuild: $costOfRebuild,
                coverageStartDate: $coverageStartDate,
                policyTerm: $policyTerm,
                advisorTollFreePhone: $advisorTollFreePhone,
                notes: $notes,
                comprehensiveWaterForgiveness: $comprehensiveWaterForgiveness,
                renewalDate: $renewalDate,
                personalPropertyCoverage: $personalPropertyCoverage,
                inclusions: $inclusions,
                exclusions: $exclusions,
                comprehensiveWater: $comprehensiveWater,
                documents: $documents,
                discounts: $discounts,
                advisorName: $advisorName,
                advisorPhone: $advisorPhone,
                advisorEmail: $advisorEmail,
                advisorAddress: $advisorAddress,
                advisorWebsite: $advisorWebsite,
                advisorCompanyName: $advisorCompanyName
            }
        ) {
            errors
            insurance {
                id
            }
        }
    }
`

export const PROPERTY_INVENTORY_ITEM_CREATE = gql`
    mutation propertyInventoryItemCreate(
        $apiKey: String!,
        $propertyId: ID!, 
        $name: String!,
        $model: String!,
        $itemType: String!,
        $propertyInsuranceId: ID,
        $propertyBuildingAreaIds: [ID!]!
        $documents: [ID!],
        $category: String!,
        $featureType: String!,
        $surfaceFinish: String!,
        $ownershipType: String,
        $manufacturer: String,
        $colourName: String,
        $colourCode: String,
        $condition: String,
        $conditionRating: String,
        $status: String,
        $purchasePrice: Float,
        $purchaseDate: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $expectedLifespan: String,
        $age: String,
        $instillationDate: String,
        $isFinanced: Boolean,
        $serviceDate: String,
        $serialNumber: String,
        $description: String,
        $retailerName: String,
        $retailerPhone: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $rentMonthlyAmount: Float,
        $rentContractExpiry: String,
        $rentContractLength: String,
        $financingMonthlyAmount: Float,
        $financingExpiry: String,
        $financingAccountNumber: String,
        $financingCompanyName: String,
        $financingTerm: String,
        $financingContractStartDate: String
    ){
        propertyInventoryItemCreate(
            input: {
                apiKey: $apiKey, 
                propertyId: $propertyId, 
                name: $name,
                itemType: $itemType,
                documents: $documents,
                propertyInsuranceId: $propertyInsuranceId,
                propertyBuildingAreaIds: $propertyBuildingAreaIds,
                model: $model,
                category: $category,
                featureType: $featureType,
                surfaceFinish: $surfaceFinish,
                ownershipType: $ownershipType,
                manufacturer: $manufacturer,
                colourName: $colourName,
                colourCode: $colourCode,
                condition: $condition,
                conditionRating: $conditionRating,
                status: $status,
                purchasePrice: $purchasePrice,
                purchaseDate: $purchaseDate,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                expectedLifespan: $expectedLifespan,
                age: $age,
                instillationDate: $instillationDate,
                isFinanced: $isFinanced,
                serviceDate: $serviceDate,
                serialNumber: $serialNumber,
                description: $description,
                retailerName: $retailerName,
                retailerPhone: $retailerPhone,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractExpiry: $rentContractExpiry,
                rentContractLength: $rentContractLength,
                financingMonthlyAmount: $financingMonthlyAmount,
                financingExpiry: $financingExpiry,
                financingAccountNumber: $financingAccountNumber,
                financingCompanyName: $financingCompanyName,
                financingTerm: $financingTerm,
                financingContractStartDate: $financingContractStartDate
            }
        ) {
            propertyInventoryItem {
                id
            }
            errors
        }
    }
`

export const PROPERTY_INVENTORY_ITEM_UPDATE = gql`
    mutation propertyInventoryItemUpdate(
        $apiKey: String!,
        $itemId: ID!,
        $itemType: String!,
        $name: String!,
        $model: String!,
        $propertyBuildingAreaIds: [ID!]!
        $propertyInsuranceId: ID,
        $documents: [ID!],
        $category: String!,
        $featureType: String!,
        $surfaceFinish: String!,
        $ownershipType: String,
        $manufacturer: String,
        $colourName: String,
        $colourCode: String,
        $condition: String,
        $conditionRating: String,
        $status: String,
        $purchasePrice: Float,
        $purchaseDate: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $expectedLifespan: String,
        $age: String,
        $instillationDate: String,
        $isFinanced: Boolean,
        $serviceDate: String,
        $serialNumber: String,
        $description: String,
        $retailerName: String,
        $retailerPhone: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $rentMonthlyAmount: Float,
        $rentContractExpiry: String,
        $rentContractLength: String,
        $financingMonthlyAmount: Float,
        $financingExpiry: String,
        $financingAccountNumber: String,
        $financingCompanyName: String,
        $financingTerm: String,
        $financingContractStartDate: String
    ) {
    propertyInventoryItemUpdate(
        input: {
            apiKey: $apiKey,
            itemId: $itemId,
            name: $name,
            itemType: $itemType,
            documents: $documents,
            propertyInsuranceId: $propertyInsuranceId,
            propertyBuildingAreaIds: $propertyBuildingAreaIds,
            model: $model,
            category: $category,
            featureType: $featureType,
            surfaceFinish: $surfaceFinish,
            ownershipType: $ownershipType,
            manufacturer: $manufacturer,
            colourName: $colourName,
            colourCode: $colourCode,
            condition: $condition,
            conditionRating: $conditionRating,
            status: $status,
            purchasePrice: $purchasePrice,
            purchaseDate: $purchaseDate,
            warrantyTerm: $warrantyTerm,
            warrantyExpiration: $warrantyExpiration,
            expectedLifespan: $expectedLifespan,
            age: $age,
            instillationDate: $instillationDate,
            isFinanced: $isFinanced,
            serviceDate: $serviceDate,
            serialNumber: $serialNumber,
            description: $description,
            retailerName: $retailerName,
            retailerPhone: $retailerPhone,
            retailerAddress: $retailerAddress,
            retailerEmail: $retailerEmail,
            rentMonthlyAmount: $rentMonthlyAmount,
            rentContractExpiry: $rentContractExpiry,
            rentContractLength: $rentContractLength,
            financingMonthlyAmount: $financingMonthlyAmount,
            financingExpiry: $financingExpiry,
            financingAccountNumber: $financingAccountNumber,
            financingCompanyName: $financingCompanyName,
            financingTerm: $financingTerm,
            financingContractStartDate: $financingContractStartDate
        }
    )   {
            propertyInventoryItem {
                id
            }
            errors
        }
    }
  
`

export const PROPERTY_PERMIT_SEARCH = gql`
    mutation propertyPermitSearch(
        $apiKey: String!,
        $address: String!, 
        $lat: Float!,
        $lng: Float!, 
        $streetNum: String!,
        $city: String!,
        $province: String!,
        $postalCode: String!,
        $dateSort: Boolean,
        $country: String!,
        $limit: String!,
        $offset: String!,
        $radius: String!,
        $type: String,
        $status: String,
        $specific: Boolean,
    )
    {
        propertyPermitSearch(
            input: {
                apiKey: $apiKey, 
                address: $address, 
                lat: $lat, 
                lng: $lng, 
                streetNum: $streetNum, 
                city: $city, 
                dateSort: $dateSort,
                province: $province, 
                postalCode: $postalCode, 
                country: $country, 
                limit: $limit, 
                offset: $offset, 
                radius: $radius,
                type: $type,
                status: $status,
                specific: $specific
            }
        ) 
        {
            total
            permits {
                id
                addressFull
                closedDate
                applicationDate
                description
                dwellingsCreated
                dwellingsLost
                estConstCost
                issuedDate
                lat
                lng
                municipality
                neighbourhoodName
                permitNum
                permitType
                postalCode
                status
                structureType
            }
            errors
        }
    }
`

export const PROPERTY_INVENTORY_ITEM_DELETE = gql`
    mutation propertyPermitSearch(
        $apiKey: String!,
        $itemId: ID!, 
    ) {
        propertyInventoryItemDelete(input: {apiKey: $apiKey, itemId: $itemId}) {
            errors
            success
        }
    }
  
`

export const PROPERTY_UPDATE = gql`
    mutation propertyUpdate(
        $apiKey: String!,
        $name: String,
        $propertyId: ID!,
        $landDepth: Float,
        $landFrontage: Float,
        $landUnit: String,
        $acres: Float,
        $neighbourhoodInfluences: [String!],
        $managmentCompany: String,
        $managerName: String,
        $managerEmail: String,
        $managerPhoneNum: String,
        $managerAddress: String,
        $superintendantName: String,
        $superintendantPhoneNumber: String,
        $desc: String,
        $title: String,
        $propertyTaxes: Float,
        $propertyType: String,
        $userLat: Float,
        $userLng: Float,
    ){
        propertyUpdate(
            input: {
                apiKey: $apiKey,
                name: $name,
                propertyId: $propertyId,
                landDepth: $landDepth,
                landFrontage: $landFrontage,
                landUnit: $landUnit,
                acres: $acres,
                neighbourhoodInfluences: $neighbourhoodInfluences,
                managmentCompany: $managmentCompany,
                managerName: $managerName,
                managerEmail: $managerEmail,
                managerPhoneNum: $managerPhoneNum,
                managerAddress: $managerAddress, 
                superintendantName: $superintendantName,
                superintendantPhoneNumber: $superintendantPhoneNumber,
                desc: $desc,
                title: $title,
                propertyTaxes: $propertyTaxes,
                propertyType: $propertyType,
                userLat: $userLat,
                userLng: $userLng
            }
        ){
            errors
            property {
                id
                landFrontage
                landDepth
                landUnit
                acres
                userCoordinates{
                    lat
                    lng
                }
                propertyType
            }
        }
    }
  
`

export const UPDATE_PASSWORD = gql`
    mutation updatePassword(
        $apiKey: String!,
        $currentPassword: String!,
        $newPassword: String!,
        $newPasswordConfirm: String!,
    ){
        updatePassword(
            input: {
                apiKey: $apiKey, 
                currentPassword: $currentPassword, 
                newPassword: $newPassword, 
                newPasswordConfirm: $newPasswordConfirm
            }
        ) {
            success
            errors
        }
    }
`

export const PROPERTY_SET_ONBOARDED = gql`
    mutation  propertySetOnboarded(
        $apiKey: String!,
        $propertyId: ID!,
    ){
        propertySetOnboarded(input: {apiKey: $apiKey, propertyId: $propertyId}) {
            errors
            property {
                id
                onboarded
            }
        }
    }
`

export const PROPERTY_TRANSFER = gql`
    mutation propertyTransfer(
        $apiKey: String!,
        $propertyId: ID!,
        $firstName: String!,
        $lastName: String!,
        $recipientEmail: String!
    ){
        propertyTransfer( input: {
            apiKey: $apiKey,
            propertyId: $propertyId,
            firstName: $firstName,
            lastName: $lastName,
            recipientEmail: $recipientEmail
        }){
            errors
            message
        }
    }
`

// Systems Components - Create
export const CREATE_COOLING_COMPONENT = gql`
    mutation propertyBuildingCoolingSystemComponentCreate(
        $apiKey: String!,
        $propertyBuildingCoolingSystemId: ID!,
        $name: String,
        $kind: String,
        $componentType: String,
        $ownership: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingCoolingSystemComponentCreate(
            input: {
                apiKey: $apiKey,
                propertyBuildingCoolingSystemId: $propertyBuildingCoolingSystemId,
                name: $name,
                kind: $kind,
                componentType: $componentType,
                ownership: $ownership,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingCoolingSystemComponent {
                brand
                componentType
                createdAt
                documents {
                    category
                    createdAt
                    desc
                    documentType
                    expiry
                    file {
                        contentType
                        createdAt
                        fileName
                        filePath
                        id
                        size
                        updatedAt
                        metadata
                    }
                    id
                    name
                    propertyBuildingAreaId
                    updatedAt
                    year
                }
                id
                images {
                    updatedAt
                    size
                    metadata
                    id
                    filePath
                    fileName
                    createdAt
                    contentType
                }
                isRented
                kind
                model
                modelYear
                name
                notes
                ownership
                purchaseDate
                purchasePrice
                rentContractExpiry
                rentContractLength
                rentMonthlyAmount
                retailerAddress
                retailerEmail
                retailerName
                retailerPhone
                updatedAt
                warrantyExpiration
                serialNumber
                warrantyTerm
                installersName
                installationDate
            }
        }
    }
`
export const CREATE_HEATING_COMPONENT = gql`
    mutation propertyBuildingHeatingSystemComponentCreate(
        $apiKey: String!,
        $propertyBuildingHeatingSystemId: ID!,
        $name: String,
        $kind: String,
        $componentType: String,
        $fuel: String,
        $ownership: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingHeatingSystemComponentCreate(
            input: {
                apiKey: $apiKey,
                propertyBuildingHeatingSystemId: $propertyBuildingHeatingSystemId,
                name: $name,
                kind: $kind,
                componentType: $componentType,
                fuel: $fuel,
                ownership: $ownership,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingHeatingSystemComponent {
                componentType
                createdAt
                fuel
                id
                kind
                name
                ownership
                updatedAt
                brand
                documents {
                    category
                    createdAt
                    desc
                    documentType
                    expiry
                    file {
                        contentType
                        createdAt
                        fileName
                        filePath
                        id
                        size
                        updatedAt
                        metadata
                    }
                    id
                    name
                    propertyBuildingAreaId
                    updatedAt
                    year
                }
                images {
                    updatedAt
                    size
                    metadata
                    id
                    filePath
                    fileName
                    createdAt
                    contentType
                }
                isRented
                model
                modelYear
                notes
                purchaseDate
                purchasePrice
                rentContractExpiry
                rentContractLength
                rentMonthlyAmount
                retailerAddress
                retailerEmail
                retailerName
                retailerPhone
                warrantyExpiration
                serialNumber
                warrantyTerm
                installersName
                installationDate
            }
        }
    }
`

export const CREATE_ELECTRICAL_COMPONENT = gql`
    mutation propertyBuildingElectricalSystemComponentCreate(
        $apiKey: String!,
        $propertyBuildingElectricalSystemId: ID!,
        $name: String,
        $kind: String,
        $componentType: String,
        $size: String,
        $age: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingElectricalSystemComponentCreate(
            input: {
                apiKey: $apiKey,
                propertyBuildingElectricalSystemId: $propertyBuildingElectricalSystemId,
                name: $name,
                kind: $kind,
                componentType: $componentType,
                size: $size,
                age: $age,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingElectricalSystemComponent {
                age
                brand
                componentType
                createdAt
                documents {
                  category
                  createdAt
                  desc
                  documentType
                  expiry
                  file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    size
                    updatedAt
                    metadata
                  }
                  id
                  name
                  propertyBuildingAreaId
                  updatedAt
                  year
                }
                id
                images {
                  contentType
                  createdAt
                  fileName
                  filePath
                  id
                  metadata
                  size
                  updatedAt
                }
                isRented
                kind
                model
                modelYear
                name
                notes
                purchaseDate
                purchasePrice
                rentContractExpiry
                rentContractLength
                rentMonthlyAmount
                retailerAddress
                retailerEmail
                retailerName
                retailerPhone
                serialNumber
                size
                updatedAt
                warrantyExpiration
                warrantyTerm
                installersName
                installationDate
            }
        }
    }
`

export const CREATE_WATER_COMPONENT = gql`
    mutation propertyBuildingWaterSystemComponentCreate(
        $apiKey: String!,
        $propertyBuildingWaterSystemId: ID!,
        $kind: String,
        $name: String,
        $componentType: String,
        $fuel: String,
        $age: String,
        $ownership: String,
        $wellType: String,
        $sumpPump: Boolean,
        $batteryBackup: Boolean,
        $source: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingWaterSystemComponentCreate( 
            input:{
                apiKey: $apiKey,
                propertyBuildingWaterSystemId: $propertyBuildingWaterSystemId,
                kind: $kind,
                name: $name,
                componentType: $componentType,
                fuel: $fuel,
                age: $age,
                ownership: $ownership,
                wellType: $wellType,
                sumpPump: $sumpPump,
                batteryBackup: $batteryBackup,
                source: $source,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
        }){
            errors
            propertyBuildingWaterSystemComponent {
                age
                batteryBackup
                componentType
                createdAt
                fuel
                id
                kind
                name
                ownership
                sumpPump
                updatedAt
                wellType
                brand
                documents {
                    category
                    createdAt
                    desc
                    documentType
                    expiry
                    file {
                        contentType
                        createdAt
                        fileName
                        filePath
                        id
                        size
                        updatedAt
                        metadata
                    }
                    id
                    name
                    propertyBuildingAreaId
                    updatedAt
                    year
                }
                images {
                    updatedAt
                    size
                    metadata
                    id
                    filePath
                    fileName
                    createdAt
                    contentType
                }
                isRented
                model
                modelYear
                notes
                purchaseDate
                purchasePrice
                rentContractExpiry
                rentContractLength
                rentMonthlyAmount
                retailerAddress
                retailerEmail
                retailerName
                retailerPhone
                warrantyExpiration
                serialNumber
                warrantyTerm
                installersName
                installationDate
            }
        }

    }
`

export const CREATE_WASTE_WATER_COMPONENT = gql`
    mutation propertyBuildingWasteSystemComponentCreate(
        $apiKey: String!,
        $propertyBuildingWasteSystemId: ID!,
        $name: String,
        $kind: String,
        $componentType: String,
        $source: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingWasteSystemComponentCreate(
            input:{
                apiKey: $apiKey,
                propertyBuildingWasteSystemId: $propertyBuildingWasteSystemId,
                name: $name,
                kind: $kind,
                componentType: $componentType,
                source: $source,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingWasteSystemComponent {
              componentType
              createdAt
              id
              kind
              name
              source
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    size
                    updatedAt
                    metadata
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                updatedAt
                size
                metadata
                id
                filePath
                fileName
                createdAt
                contentType
              }
              isRented
              model
              modelYear
              notes
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              warrantyExpiration
              serialNumber
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`

export const CREATE_ROOF_COMPONENT = gql`
    mutation propertyBuildingRoofSystemComponentCreate(
        $apiKey: String!,
        $age: String,
        $propertyBuildingRoofSystemId: ID!,
        $name: String,
        $kind: String,
        $brand: String,
        $covering: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingRoofSystemComponentCreate(
            input:{
                apiKey: $apiKey,
                age: $age,
                covering: $covering,
                propertyBuildingRoofSystemId: $propertyBuildingRoofSystemId,
                name: $name,
                kind: $kind,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingRoofSystemComponent {
              age
              covering
              createdAt
              id
              kind
              name
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    size
                    updatedAt
                    metadata
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                updatedAt
                size
                metadata
                id
                filePath
                fileName
                createdAt
                contentType
              }
              isRented
              model
              modelYear
              notes
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              warrantyExpiration
              serialNumber
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`
export const CREATE_EXT_SID_COMPONENT = gql`
    mutation propertyBuildingExtsidSystemComponentCreate(
        $coverage: Float,
        $material: String,
        $apiKey: String!,
        $propertyBuildingExtsidSystemId: ID!,
        $name: String,
        $kind: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingExtsidSystemComponentCreate(
            input:{
                coverage: $coverage,
                material: $material,
                apiKey: $apiKey,
                propertyBuildingExtsidSystemId: $propertyBuildingExtsidSystemId,
                name: $name,
                kind: $kind,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingExtsidSystemComponent {
              coverage
              material
              createdAt
              id
              kind
              name
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    size
                    updatedAt
                    metadata
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                updatedAt
                size
                metadata
                id
                filePath
                fileName
                createdAt
                contentType
              }
              isRented
              model
              modelYear
              notes
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              warrantyExpiration
              serialNumber
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`

export const CREATE_EXT_DOOR_COMPONENT = gql`
    mutation propertyBuildingExtdrSystemComponentCreate(
        $componentType: String,
        $condition: String,
        $insulated: Boolean,
        $material: String,
        $apiKey: String!,
        $propertyBuildingExtdrSystemId: ID!,
        $name: String,
        $kind: String,
        $brand: String,
        $level: String,
        $location: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingExtdrSystemComponentCreate(
            input:{
                componentType: $componentType,
                condition: $condition,
                insulated: $insulated,
                material: $material,
                apiKey: $apiKey,
                propertyBuildingExtdrSystemId: $propertyBuildingExtdrSystemId,
                name: $name,
                kind: $kind,
                brand: $brand,
                level: $level,
                location: $location,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingExtdrSystemComponent {
              componentType
              condition
              insulated
              material
              createdAt
              id
              kind
              name
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    size
                    updatedAt
                    metadata
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                updatedAt
                size
                metadata
                id
                filePath
                fileName
                createdAt
                contentType
              }
              isRented
              level
              location
              model
              modelYear
              notes
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              warrantyExpiration
              serialNumber
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`

export const CREATE_WINDOW_COMPONENT = gql`
    mutation propertyBuildingWindowSystemComponentCreate(
        $condition: String,
        $material: String,
        $paneCount: String,
        $style: String,  
        $apiKey: String!,
        $propertyBuildingWindowSystemId: ID!,
        $name: String,
        $kind: String,
        $brand: String,
        $level: String,
        $location: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingWindowSystemComponentCreate(
            input:{
                condition: $condition,
                material: $material,
                paneCount: $paneCount,
                style: $style,
                apiKey: $apiKey,
                propertyBuildingWindowSystemId: $propertyBuildingWindowSystemId,
                name: $name,
                kind: $kind,
                brand: $brand,
                level: $level,
                location: $location,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingWindowSystemComponent {
              condition
              material
              paneCount
              style
              createdAt
              id
              kind
              name
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    size
                    updatedAt
                    metadata
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                updatedAt
                size
                metadata
                id
                filePath
                fileName
                createdAt
                contentType
              }
              isRented
              level
              location
              model
              modelYear
              notes
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              warrantyExpiration
              serialNumber
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`

export const CREATE_FOUNDATION_COMPONENT = gql`
    mutation propertyBuildingFoundationSystemComponentCreate(
        $componentType: String,
        $apiKey: String!,
        $propertyBuildingFoundationSystemId: ID!,
        $name: String,
        $kind: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingFoundationSystemComponentCreate(
            input:{
                componentType: $componentType,
                apiKey: $apiKey,
                propertyBuildingFoundationSystemId: $propertyBuildingFoundationSystemId,
                name: $name,
                kind: $kind,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingFoundationSystemComponent {
              componentType
              createdAt
              id
              kind
              name
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    size
                    updatedAt
                    metadata
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                updatedAt
                size
                metadata
                id
                filePath
                fileName
                createdAt
                contentType
              }
              isRented
              model
              modelYear
              notes
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              warrantyExpiration
              serialNumber
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`

// Systems Components - Edit
export const UPDATE_COOLING_COMPONENT = gql`
    mutation propertyBuildingCoolingSystemComponentUpdate(
        $apiKey: String!,
        $id: ID!,
        $name: String,
        $kind: String,
        $componentType: String,
        $ownership: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingCoolingSystemComponentUpdate(
            input: {
                apiKey: $apiKey,
                id: $id,
                name: $name,
                kind: $kind,
                componentType: $componentType,
                ownership: $ownership,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingCoolingSystemComponent {
                componentType
                createdAt
                id
                kind
                name
                notes
                ownership
                updatedAt
                brand
                documents {
                    category
                    createdAt
                    desc
                    documentType
                    expiry
                    file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    metadata
                    size
                    updatedAt
                    }
                    id
                    name
                    propertyBuildingAreaId
                    updatedAt
                    year
                }
                images {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    metadata
                    size
                    updatedAt
                }
                isRented
                model
                modelYear
                ownership
                purchaseDate
                purchasePrice
                rentContractExpiry
                rentContractLength
                rentMonthlyAmount
                retailerAddress
                retailerEmail
                retailerName
                retailerPhone
                serialNumber
                warrantyExpiration
                warrantyTerm
                installersName
                installationDate
            }
        }
    }
`

export const UPDATE_HEATING_COMPONENT = gql`
    mutation propertyBuildingHeatingSystemComponentUpdate(
        $apiKey: String!,
        $id: ID!,
        $name: String,
        $kind: String,
        $componentType: String,
        $fuel: String,
        $ownership: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingHeatingSystemComponentUpdate(
            input: {
                apiKey: $apiKey,
                id: $id,
                name: $name,
                kind: $kind,
                componentType: $componentType,
                fuel: $fuel,
                ownership: $ownership,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingHeatingSystemComponent {
                componentType
                createdAt
                fuel
                id
                kind
                name
                notes
                ownership
                updatedAt
                brand
                documents {
                    category
                    createdAt
                    desc
                    documentType
                    expiry
                    file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    metadata
                    size
                    updatedAt
                    }
                    id
                    name
                    propertyBuildingAreaId
                    updatedAt
                    year
                }
                images {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    metadata
                    size
                    updatedAt
                }
                isRented
                model
                modelYear
                ownership
                purchaseDate
                purchasePrice
                rentContractExpiry
                rentContractLength
                rentMonthlyAmount
                retailerAddress
                retailerEmail
                retailerName
                retailerPhone
                serialNumber
                warrantyExpiration
                warrantyTerm
                installersName
                installationDate
            }
        }
    }
`

export const UPDATE_ELECTRICAL_COMPONENT = gql`
    mutation propertyBuildingElectricalSystemComponentUpdate(
        $apiKey: String!,
        $id: ID!,
        $name: String,
        $kind: String,
        $componentType: String,
        $size: String,
        $age: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingElectricalSystemComponentUpdate(
            input: {
                apiKey: $apiKey,
                id: $id,
                name: $name,
                kind: $kind,
                componentType: $componentType,
                size: $size,
                age: $age,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingElectricalSystemComponent {
                age
                componentType
                createdAt
                id
                kind
                name
                notes
                size
                updatedAt
                brand
                documents {
                    category
                    createdAt
                    desc
                    documentType
                    expiry
                    file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    metadata
                    size
                    updatedAt
                    }
                    id
                    name
                    propertyBuildingAreaId
                    updatedAt
                    year
                }
                images {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    metadata
                    size
                    updatedAt
                }
                isRented
                model
                modelYear
                purchaseDate
                purchasePrice
                rentContractExpiry
                rentContractLength
                rentMonthlyAmount
                retailerAddress
                retailerEmail
                retailerName
                retailerPhone
                serialNumber
                warrantyExpiration
                warrantyTerm
                installersName
                installationDate
            }
        }
    }
`

export const UPDATE_WATER_COMPONENT = gql`
    mutation propertyBuildingWaterSystemComponentUpdate(
        $apiKey: String!,
        $id: ID!,
        $kind: String,
        $name: String,
        $componentType: String,
        $fuel: String,
        $age: String,
        $ownership: String,
        $wellType: String,
        $sumpPump: Boolean,
        $batteryBackup: Boolean,
        $source: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingWaterSystemComponentUpdate( 
            input:{
                apiKey: $apiKey,
                id: $id,
                kind: $kind,
                name: $name,
                componentType: $componentType,
                fuel: $fuel,
                age: $age,
                ownership: $ownership,
                wellType: $wellType,
                sumpPump: $sumpPump,
                batteryBackup: $batteryBackup,
                source: $source,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
        }){
            errors
            propertyBuildingWaterSystemComponent {
                age
                batteryBackup
                componentType
                createdAt
                fuel
                id
                kind
                name
                notes
                ownership
                sumpPump
                updatedAt
                wellType
                brand
                documents {
                    category
                    createdAt
                    desc
                    documentType
                    expiry
                    file {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    metadata
                    size
                    updatedAt
                    }
                    id
                    name
                    propertyBuildingAreaId
                    updatedAt
                    year
                }
                images {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    metadata
                    size
                    updatedAt
                }
                isRented
                model
                modelYear
                ownership
                purchaseDate
                purchasePrice
                rentContractExpiry
                rentContractLength
                rentMonthlyAmount
                retailerAddress
                retailerEmail
                retailerName
                retailerPhone
                serialNumber
                warrantyExpiration
                warrantyTerm
                installersName
                installationDate
            }
        }

    }
`

export const UPDATE_WASTE_WATER_COMPONENT = gql`
    mutation propertyBuildingWasteSystemComponentUpdate(
        $apiKey: String!,
        $id: ID!,
        $name: String,
        $kind: String,
        $componentType: String,
        $source: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingWasteSystemComponentUpdate(
            input:{
                apiKey: $apiKey,
                id: $id,
                name: $name,
                kind: $kind,
                componentType: $componentType,
                source: $source,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingWasteSystemComponent {
              componentType
              createdAt
              id
              kind
              name
              notes
              source
              updatedAt
              brand
              documents {
                  category
                  createdAt
                  desc
                  documentType
                  expiry
                  file {
                  contentType
                  createdAt
                  fileName
                  filePath
                  id
                  metadata
                  size
                  updatedAt
                  }
                  id
                  name
                  propertyBuildingAreaId
                  updatedAt
                  year
              }
              images {
                  contentType
                  createdAt
                  fileName
                  filePath
                  id
                  metadata
                  size
                  updatedAt
              }
              isRented
              model
              modelYear
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              serialNumber
              warrantyExpiration
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`

export const UPDATE_ROOF_COMPONENT = gql`
    mutation propertyBuildingRoofSystemComponentUpdate(
        $age: String,
        $covering: String,
        $apiKey: String!,
        $id: ID!,
        $name: String,
        $kind: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingRoofSystemComponentUpdate(
            input:{
                age: $age,
                covering: $covering,
                apiKey: $apiKey,
                id: $id,
                name: $name,
                kind: $kind,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingRoofSystemComponent {
              age
              covering
              createdAt
              id
              kind
              name
              notes
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                contentType
                createdAt
                fileName
                filePath
                id
                metadata
                size
                updatedAt
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                contentType
                createdAt
                fileName
                filePath
                id
                metadata
                size
                updatedAt
              }
              isRented
              model
              modelYear
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              serialNumber
              warrantyExpiration
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`

export const UPDATE_EXT_SID_COMPONENT = gql`
    mutation propertyBuildingExtsidSystemComponentUpdate(
        $coverage: Float,
        $material: String,
        $apiKey: String!,
        $id: ID!,
        $name: String,
        $kind: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingExtsidSystemComponentUpdate(
            input:{
                coverage: $coverage,
                material: $material,
                apiKey: $apiKey,
                id: $id,
                name: $name,
                kind: $kind,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingExtsidSystemComponent {
              coverage
              material
              createdAt
              id
              kind
              name
              notes
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                contentType
                createdAt
                fileName
                filePath
                id
                metadata
                size
                updatedAt
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                contentType
                createdAt
                fileName
                filePath
                id
                metadata
                size
                updatedAt
              }
              isRented
              model
              modelYear
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              serialNumber
              warrantyExpiration
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`
export const UPDATE_EXT_DOOR_COMPONENT = gql`
    mutation propertyBuildingExtdrSystemComponentUpdate(
        $componentType: String,
        $condition: String,
        $insulated: Boolean,
        $material: String,  
        $apiKey: String!,
        $id: ID!,
        $name: String,
        $kind: String,
        $brand: String,
        $level: String,
        $location: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingExtdrSystemComponentUpdate(
            input:{
                componentType: $componentType,
                condition: $condition,
                insulated: $insulated,
                material: $material,          
                apiKey: $apiKey,
                id: $id,
                name: $name,
                kind: $kind,
                brand: $brand,
                level: $level,
                location: $location,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingExtdrSystemComponent {
              componentType
              condition
              insulated
              material          
              createdAt
              id
              kind
              name
              notes
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                contentType
                createdAt
                fileName
                filePath
                id
                metadata
                size
                updatedAt
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                contentType
                createdAt
                fileName
                filePath
                id
                metadata
                size
                updatedAt
              }
              isRented
              level
              location
              model
              modelYear
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              serialNumber
              warrantyExpiration
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`

export const UPDATE_WINDOW_COMPONENT = gql`
    mutation propertyBuildingWindowSystemComponentUpdate(
        $condition: String,
        $material: String,
        $paneCount: String,
        $style: String,  
        $apiKey: String!,
        $id: ID!,
        $name: String,
        $kind: String,
        $brand: String,
        $level: String,
        $location: String,
        $model: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $serialNumber: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingWindowSystemComponentUpdate(
            input:{
                condition: $condition,
                material: $material,
                paneCount: $paneCount,
                style: $style,          
                apiKey: $apiKey,
                id: $id,
                name: $name,
                kind: $kind,
                brand: $brand,
                level: $level,
                location: $location,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingWindowSystemComponent {
              condition
              material
              paneCount
              style          
              createdAt
              id
              kind
              name
              notes
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                contentType
                createdAt
                fileName
                filePath
                id
                metadata
                size
                updatedAt
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                contentType
                createdAt
                fileName
                filePath
                id
                metadata
                size
                updatedAt
              }
              isRented
              level
              location
              model
              modelYear
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              serialNumber
              warrantyExpiration
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`

export const UPDATE_FOUNDATION_COMPONENT = gql`
    mutation propertyBuildingFoundationSystemComponentUpdate(
        $componentType: String,
        $apiKey: String!,
        $id: ID!,
        $name: String,
        $kind: String,
        $brand: String,
        $model: String,
        $serialNumber: String,
        $modelYear: String,
        $notes: String,
        $isRented: Boolean, 
        $installersName: String,
        $installationDate: String,
        $rentMonthlyAmount: Float,
        $rentContractLength: String,
        $rentContractExpiry: String,
        $purchaseDate: String,
        $purchasePrice: Float,
        $retailerName: String,
        $retailerAddress: String,
        $retailerEmail: String,
        $retailerPhone: String,
        $warrantyTerm: String,
        $warrantyExpiration: String,
        $documents: [ID!]
    ){
        propertyBuildingFoundationSystemComponentUpdate(
            input:{
                componentType: $componentType,
                apiKey: $apiKey,
                id: $id,
                name: $name,
                kind: $kind,
                brand: $brand,
                model: $model,
                serialNumber: $serialNumber,
                modelYear: $modelYear,
                notes: $notes,
                isRented: $isRented,
                installersName: $installersName,
                installationDate: $installationDate,
                rentMonthlyAmount: $rentMonthlyAmount,
                rentContractLength: $rentContractLength,
                rentContractExpiry: $rentContractExpiry,
                purchaseDate: $purchaseDate,
                purchasePrice: $purchasePrice,
                retailerName: $retailerName,
                retailerAddress: $retailerAddress,
                retailerEmail: $retailerEmail,
                retailerPhone: $retailerPhone,
                warrantyTerm: $warrantyTerm,
                warrantyExpiration: $warrantyExpiration,
                documents: $documents
            }
        ){
            errors
            propertyBuildingFoundationSystemComponent {
              componentType
              createdAt
              id
              kind
              name
              notes
              updatedAt
              brand
              documents {
                category
                createdAt
                desc
                documentType
                expiry
                file {
                contentType
                createdAt
                fileName
                filePath
                id
                metadata
                size
                updatedAt
                }
                id
                name
                propertyBuildingAreaId
                updatedAt
                year
              }
              images {
                contentType
                createdAt
                fileName
                filePath
                id
                metadata
                size
                updatedAt
              }
              isRented
              model
              modelYear
              purchaseDate
              purchasePrice
              rentContractExpiry
              rentContractLength
              rentMonthlyAmount
              retailerAddress
              retailerEmail
              retailerName
              retailerPhone
              serialNumber
              warrantyExpiration
              warrantyTerm
              installersName
              installationDate
            }
        }
    }
`
// TODO: add wallPhotos and accentWallPhotos when backend implemented
export const CREATE_AREA = gql`
    mutation propertyBuildingAreaCreate(
        $apiKey: String!,
        $propertyBuildingId: ID!,
        $areaType: String!,
        $roomLevel: Float,
        $intExt: String,
        $status: String,
        $name: String,
        $dimensions: String,
        $width: Float,
        $length: Float,
        $category: String,
        $description: String,
        $unit: String,
        $wallHeight: Float,
        $hasAccent: Boolean,
        $accentWallNumber: Int,
        $wallType: String,
        $wallCoverBrand: String,
        $wallCoverName: String,
        $wallName: String,
        $wallCoverCode: String,
        $wallFinish: String,
        $wallStatus: String,
        $wallNotes: String,
        $accentWallType: String,
        $accentWallCoverBrand: String,
        $accentWallCoverName: String,
        $accentWallName: String,
        $accentWallCoverCode: String,
        $accentWallFinish: String,
        $accentWallStatus: String,
        $accentWallNotes: String,
        $wallpaperWidth: Float,
        $wallpaperLength: Float,
        $ceilingType: String,
        $ceilingFinishType: String,
        $ceilingCoverBrand: String,
        $ceilingCoverName: String,
        $ceilingCoverCode: String,
        $ceilingConditionStatus: String,
        $ceilingNotes: String,
        $flooringType: String,
        $flooringSubType: String,
        $flooringCoverManufacturer: String,
        $flooringCoverCode: String,
        $flooringName: String,
        $flooringConditionStatus: String,
        $flooringNotes: String,
        $flooringIsHeated: Boolean,
        $flooringHeatingType: String
    ){
        propertyBuildingAreaCreate(
            input:{
                apiKey: $apiKey
                propertyBuildingId: $propertyBuildingId
                areaType: $areaType
                roomLevel: $roomLevel
                intExt: $intExt
                status: $status
                name: $name
                dimensions: $dimensions
                width: $width
                length: $length
                category: $category
                description: $description
                unit: $unit
                wallHeight: $wallHeight
                hasAccent: $hasAccent
                accentWallNumber: $accentWallNumber
                wallType: $wallType
                wallCoverBrand: $wallCoverBrand
                wallCoverName: $wallCoverName
                wallName: $wallName
                wallCoverCode: $wallCoverCode
                wallFinish: $wallFinish
                wallStatus: $wallStatus
                wallNotes: $wallNotes
                accentWallType: $accentWallType
                accentWallCoverBrand: $accentWallCoverBrand
                accentWallCoverName: $accentWallCoverName
                accentWallName: $accentWallName
                accentWallCoverCode: $accentWallCoverCode
                accentWallFinish: $accentWallFinish
                accentWallStatus: $accentWallStatus
                accentWallNotes: $accentWallNotes
                wallpaperWidth: $wallpaperWidth
                wallpaperLength: $wallpaperLength
                ceilingType: $ceilingType
                ceilingFinishType: $ceilingFinishType
                ceilingCoverBrand: $ceilingCoverBrand
                ceilingCoverName: $ceilingCoverName
                ceilingCoverCode: $ceilingCoverCode
                ceilingConditionStatus: $ceilingConditionStatus
                ceilingNotes: $ceilingNotes
                flooringType: $flooringType
                flooringSubType: $flooringSubType
                flooringCoverManufacturer: $flooringCoverManufacturer
                flooringCoverCode: $flooringCoverCode
                flooringName: $flooringName
                flooringConditionStatus: $flooringConditionStatus
                flooringNotes: $flooringNotes
                flooringIsHeated: $flooringIsHeated
                flooringHeatingType: $flooringHeatingType
            }
        ) {
            errors
            propertyBuildingArea {
                accentWallCoverBrand
                accentWallCoverCode
                accentWallCoverName
                accentWallFinish
                accentWallName
                accentWallNotes
                accentWallNumber
                accentWallPhotos {
                    contentType
                    fileName
                    filePath
                    id
                    size
                }
                accentWallStatus
                accentWallType
                additionalPhotos {
                    contentType
                    fileName
                    filePath
                    id
                    size
                }
                areaType
                category
                ceilingConditionStatus
                ceilingCoverBrand
                ceilingCoverCode
                ceilingCoverName
                ceilingFinishType
                ceilingNotes
                ceilingType
                createdAt
                description
                dimensions
                width
                length
                flooringConditionStatus
                flooringCoverCode
                flooringCoverManufacturer
                flooringHeatingType
                flooringIsHeated
                flooringName
                flooringNotes
                flooringSubType
                flooringType
                hasAccent
                id
                intExt
                mainPhoto {
                    contentType
                    fileName
                    filePath
                    id
                    size
                }
                name
                roomLevel
                status
                unit
                wallCoverBrand
                wallCoverCode
                wallCoverName
                wallFinish
                wallHeight
                wallName
                wallNotes
                wallPhotos {
                    contentType
                    fileName
                    filePath
                    id
                    size
                }
                wallStatus
                wallType
            }
        }
    }
`
// TODO: add wallPhotos and accentWallPhotos when backend implemented
export const UPDATE_AREA = gql`
    mutation propertyBuildingAreaUpdate(
        $apiKey: String!,
        $id: ID!,
        $areaType: String!,
        $roomLevel: Float,
        $intExt: String,
        $status: String,
        $name: String,
        $dimensions: String,
        $width: Float,
        $length: Float,
        $category: String,
        $description: String,
        $unit: String,
        $wallHeight: Float,
        $hasAccent: Boolean,
        $accentWallNumber: Int,
        $wallType: String,
        $wallCoverBrand: String,
        $wallCoverName: String,
        $wallName: String,
        $wallCoverCode: String,
        $wallFinish: String,
        $wallStatus: String,
        $wallNotes: String,
        $accentWallType: String,
        $accentWallCoverBrand: String,
        $accentWallCoverName: String,
        $accentWallName: String,
        $accentWallCoverCode: String,
        $accentWallFinish: String,
        $accentWallStatus: String,
        $accentWallNotes: String,
        $wallpaperWidth: Float,
        $wallpaperLength: Float,
        $ceilingType: String,
        $ceilingFinishType: String,
        $ceilingCoverBrand: String,
        $ceilingCoverName: String,
        $ceilingCoverCode: String,
        $ceilingConditionStatus: String,
        $ceilingNotes: String,
        $flooringType: String,
        $flooringSubType: String,
        $flooringCoverManufacturer: String,
        $flooringCoverCode: String,
        $flooringName: String,
        $flooringConditionStatus: String,
        $flooringNotes: String,
        $flooringIsHeated: Boolean,
        $flooringHeatingType: String
    ){
        propertyBuildingAreaUpdate(
            input:{
                apiKey: $apiKey
                id: $id
                areaType: $areaType
                roomLevel: $roomLevel
                intExt: $intExt
                status: $status
                name: $name
                dimensions: $dimensions
                width: $width
                length: $length
                category: $category
                description: $description
                unit: $unit
                wallHeight: $wallHeight
                hasAccent: $hasAccent
                accentWallNumber: $accentWallNumber
                wallType: $wallType
                wallCoverBrand: $wallCoverBrand
                wallCoverName: $wallCoverName
                wallName: $wallName
                wallCoverCode: $wallCoverCode
                wallFinish: $wallFinish
                wallStatus: $wallStatus
                wallNotes: $wallNotes
                accentWallType: $accentWallType
                accentWallCoverBrand: $accentWallCoverBrand
                accentWallCoverName: $accentWallCoverName
                accentWallName: $accentWallName
                accentWallCoverCode: $accentWallCoverCode
                accentWallFinish: $accentWallFinish
                accentWallStatus: $accentWallStatus
                accentWallNotes: $accentWallNotes
                wallpaperWidth: $wallpaperWidth
                wallpaperLength: $wallpaperLength
                ceilingType: $ceilingType
                ceilingFinishType: $ceilingFinishType
                ceilingCoverBrand: $ceilingCoverBrand
                ceilingCoverName: $ceilingCoverName
                ceilingCoverCode: $ceilingCoverCode
                ceilingConditionStatus: $ceilingConditionStatus
                ceilingNotes: $ceilingNotes
                flooringType: $flooringType
                flooringSubType: $flooringSubType
                flooringCoverManufacturer: $flooringCoverManufacturer
                flooringCoverCode: $flooringCoverCode
                flooringName: $flooringName
                flooringConditionStatus: $flooringConditionStatus
                flooringNotes: $flooringNotes
                flooringIsHeated: $flooringIsHeated
                flooringHeatingType: $flooringHeatingType
            }
        ) {
            errors
            propertyBuildingArea {
                accentWallCoverBrand
                accentWallCoverCode
                accentWallCoverName
                accentWallFinish
                accentWallName
                accentWallNotes
                accentWallNumber
                accentWallPhotos {
                    contentType
                    fileName
                    filePath
                    id
                    size
                }
                accentWallStatus
                accentWallType
                additionalPhotos {
                    contentType
                    fileName
                    filePath
                    id
                    size
                }
                areaType
                category
                ceilingConditionStatus
                ceilingCoverBrand
                ceilingCoverCode
                ceilingCoverName
                ceilingFinishType
                ceilingNotes
                ceilingType
                createdAt
                description
                dimensions
                width
                length
                flooringConditionStatus
                flooringCoverCode
                flooringCoverManufacturer
                flooringHeatingType
                flooringIsHeated
                flooringName
                flooringNotes
                flooringSubType
                flooringType
                hasAccent
                id
                intExt
                mainPhoto {
                    contentType
                    fileName
                    filePath
                    id
                    size
                }
                name
                roomLevel
                status
                unit
                wallCoverBrand
                wallCoverCode
                wallCoverName
                wallFinish
                wallHeight
                wallName
                wallNotes
                wallPhotos {
                    contentType
                    fileName
                    filePath
                    id
                    size
                }
                wallStatus
                wallType
            }
        }
    }
`
