import { gql } from '@apollo/client'

export const SELF = gql`
    query userSelf{
        userSelf {
            uploadedFileId
            agent
            bio
            email
            phoneNumber
            firstName
            id
            lastName
            hasPrimaryResidence
        }
    }
  
`

export const PROPERTY_VALUE = gql`
    query property($id: ID!){
        property (id: $id){
            id
            estimatedPropertyValue
            estimatedPropertyValueChange
        }
    }
  
`

export const PROPERTY_VALUES_TREND = gql`
    query property($id: ID!){
        property (id: $id){
            id
            annualEstimatedPropertyValues {
              name
              pv
            }
        }
    }
  
`

export const PROPERTY_BUILDINGS_SYSTEMS = gql`
    query property($id: ID!){
        property (id: $id){
            id
            propertyBuildings {
                propertyBuildingCoolingSystems {
                    id
                    propertyBuildingCoolingSystemComponents {
                        installationDate
                        installersName
                        id
                        componentType
                        kind
                        name
                        ownership
                        updatedAt
                        createdAt
                        brand
                        isRented
                        model
                        modelYear
                        notes
                        purchaseDate
                        purchasePrice
                        rentContractExpiry
                        rentContractLength
                        rentMonthlyAmount
                        retailerAddress
                        retailerEmail
                        retailerName
                        retailerPhone
                        serialNumber
                        warrantyExpiration
                        warrantyTerm
                        documents {
                            category
                            createdAt
                            desc
                            documentType
                            expiry
                            file {
                                contentType
                                createdAt
                                fileName
                                filePath
                                id
                                size
                                updatedAt
                                metadata
                            }
                            id
                            name
                            propertyBuildingAreaId
                            updatedAt
                            year
                        }
                        images {
                            updatedAt
                            size
                            metadata
                            id
                            filePath
                            fileName
                            createdAt
                            contentType
                        }
                    }
                }
                propertyBuildingElectricalSystems {
                    id
                    propertyBuildingElectricalSystemComponents {
                        installationDate
                        installersName
                        id
                        age
                        componentType
                        createdAt
                        kind
                        name
                        size
                        updatedAt
                        brand
                        isRented
                        model
                        modelYear
                        notes
                        purchaseDate
                        purchasePrice
                        rentContractExpiry
                        rentContractLength
                        rentMonthlyAmount
                        retailerAddress
                        retailerEmail
                        retailerName
                        retailerPhone
                        serialNumber
                        warrantyExpiration
                        warrantyTerm
                        documents {
                            year
                            updatedAt
                            propertyBuildingAreaId
                            name
                            id
                            file {
                                updatedAt
                                size
                                metadata
                                id
                                filePath
                                fileName
                                createdAt
                                contentType
                            }
                            expiry
                            documentType
                            desc
                            createdAt
                            category
                        }
                        images {
                            updatedAt
                            size
                            metadata
                            id
                            filePath
                            fileName
                            createdAt
                            contentType
                        }
                    }
                }
                propertyBuildingHeatingSystems {
                    id
                    propertyBuildingHeatingSystemComponents {
                        installationDate
                        installersName
                        id
                        componentType
                        createdAt
                        fuel
                        kind
                        name
                        ownership
                        updatedAt
                        brand
                        isRented
                        model
                        modelYear
                        notes
                        purchaseDate
                        purchasePrice
                        rentContractExpiry
                        rentContractLength
                        rentMonthlyAmount
                        retailerAddress
                        retailerEmail
                        retailerName
                        retailerPhone
                        serialNumber
                        warrantyExpiration
                        warrantyTerm
                        documents {
                            year
                            updatedAt
                            propertyBuildingAreaId
                            name
                            id
                            file {
                                updatedAt
                                size
                                metadata
                                id
                                filePath
                                fileName
                                createdAt
                                contentType
                            }
                            expiry
                            documentType
                            desc
                            createdAt
                            category
                        }
                        images {
                            updatedAt
                            size
                            metadata
                            id
                            filePath
                            fileName
                            createdAt
                            contentType
                        }
                    }
                }
                propertyBuildingWasteSystems {
                    id
                    propertyBuildingWasteSystemComponents {
                        installationDate
                        installersName
                        id
                        componentType
                        createdAt
                        kind
                        name
                        source
                        updatedAt
                        brand
                        isRented
                        model
                        modelYear
                        notes
                        purchaseDate
                        purchasePrice
                        rentContractExpiry
                        rentContractLength
                        rentMonthlyAmount
                        retailerAddress
                        retailerEmail
                        retailerName
                        retailerPhone
                        serialNumber
                        warrantyExpiration
                        warrantyTerm
                        documents {
                            year
                            updatedAt
                            propertyBuildingAreaId
                            name
                            id
                            file {
                                updatedAt
                                size
                                metadata
                                id
                                filePath
                                fileName
                                createdAt
                                contentType
                            }
                            expiry
                            documentType
                            desc
                            createdAt
                            category
                        }
                        images {
                            updatedAt
                            size
                            metadata
                            id
                            filePath
                            fileName
                            createdAt
                            contentType
                        }
                    }
                }
                propertyBuildingWaterSystems {
                    id
                    propertyBuildingWaterSystemComponents {
                        installationDate
                        installersName
                        id
                        age
                        batteryBackup
                        componentType
                        createdAt
                        fuel
                        kind
                        name
                        ownership
                        sumpPump
                        updatedAt
                        wellType
                        brand
                        isRented
                        model
                        modelYear
                        notes
                        purchaseDate
                        purchasePrice
                        rentContractExpiry
                        rentContractLength
                        rentMonthlyAmount
                        retailerAddress
                        retailerEmail
                        retailerName
                        retailerPhone
                        serialNumber
                        source
                        warrantyExpiration
                        warrantyTerm
                        documents {
                            year
                            updatedAt
                            propertyBuildingAreaId
                            name
                            id
                            file {
                                updatedAt
                                size
                                metadata
                                id
                                filePath
                                fileName
                                createdAt
                                contentType
                            }
                            expiry
                            documentType
                            desc
                            createdAt
                            category
                        }
                        images {
                            updatedAt
                            size
                            metadata
                            id
                            filePath
                            fileName
                            createdAt
                            contentType
                        }
                    }
                }
                propertyBuildingExtdrSystems {
                    id
                    propertyBuildingExtdrSystemComponents {
                      installationDate
                      installersName
                      condition
                      insulated
                      material
                      brand
                      componentType
                      createdAt
                      isRented
                      id
                      kind
                      level
                      location
                      model
                      modelYear
                      name
                      notes
                      purchaseDate
                      purchasePrice
                      rentContractExpiry
                      rentContractLength
                      rentMonthlyAmount
                      retailerAddress
                      retailerEmail
                      retailerName
                      retailerPhone
                      serialNumber
                      updatedAt
                      warrantyExpiration
                      warrantyTerm
                      documents {
                        category
                        createdAt
                        desc
                        documentType
                        expiry
                        file {
                            updatedAt
                            size
                            metadata
                            id
                            filePath
                            fileName
                            createdAt
                            contentType
                        }
                        id
                        name
                        propertyBuildingAreaId
                        updatedAt
                        year
                      }
                      images {
                        contentType
                        createdAt
                        fileName
                        filePath
                        id
                        metadata
                        size
                        updatedAt
                      }
                    }
                }
                propertyBuildingExtsidSystems {
                    id
                    propertyBuildingExtsidSystemComponents {
                      installationDate
                      installersName
                      coverage
                      material
                      brand
                      createdAt
                      isRented
                      id
                      kind
                      model
                      modelYear
                      name
                      notes
                      purchaseDate
                      purchasePrice
                      rentContractExpiry
                      rentContractLength
                      rentMonthlyAmount
                      retailerAddress
                      retailerEmail
                      retailerName
                      retailerPhone
                      serialNumber
                      updatedAt
                      warrantyExpiration
                      warrantyTerm
                      documents {
                        category
                        createdAt
                        desc
                        documentType
                        expiry
                        file {
                            updatedAt
                            size
                            metadata
                            id
                            filePath
                            fileName
                            createdAt
                            contentType
                        }
                        id
                        name
                        propertyBuildingAreaId
                        updatedAt
                        year
                      }
                      images {
                        contentType
                        createdAt
                        fileName
                        filePath
                        id
                        metadata
                        size
                        updatedAt
                      }
                    }
                }
                propertyBuildingFoundationSystems {
                    id
                    propertyBuildingFoundationSystemComponents {
                        installationDate
                        installersName
                        brand
                        componentType
                        createdAt
                        isRented
                        id
                        kind
                        model
                        modelYear
                        name
                        notes
                        purchaseDate
                        purchasePrice
                        rentContractExpiry
                        rentContractLength
                        rentMonthlyAmount
                        retailerAddress
                        retailerEmail
                        retailerName
                        retailerPhone
                        serialNumber
                        updatedAt
                        warrantyExpiration
                        warrantyTerm
                        documents {
                          category
                          createdAt
                          desc
                          documentType
                          expiry
                          file {
                            updatedAt
                            size
                            metadata
                            id
                            filePath
                            fileName
                            createdAt
                            contentType
                        }
                          id
                          name
                          propertyBuildingAreaId
                          updatedAt
                          year
                        }
                        images {
                          contentType
                          createdAt
                          fileName
                          filePath
                          id
                          metadata
                          size
                          updatedAt
                        }
                    }
                }
                propertyBuildingRoofSystems {
                    id
                    propertyBuildingRoofSystemComponents {
                      installationDate
                      installersName
                      age
                      covering
                      brand
                      createdAt
                      isRented
                      id
                      kind
                      model
                      modelYear
                      name
                      notes
                      purchaseDate
                      purchasePrice
                      rentContractExpiry
                      rentContractLength
                      rentMonthlyAmount
                      retailerAddress
                      retailerEmail
                      retailerName
                      retailerPhone
                      serialNumber
                      updatedAt
                      warrantyExpiration
                      warrantyTerm
                      documents {
                        category
                        createdAt
                        desc
                        documentType
                        expiry
                        file {
                            updatedAt
                            size
                            metadata
                            id
                            filePath
                            fileName
                            createdAt
                            contentType
                        }
                        id
                        name
                        propertyBuildingAreaId
                        updatedAt
                        year
                      }
                      images {
                        contentType
                        createdAt
                        fileName
                        filePath
                        id
                        metadata
                        size
                        updatedAt
                      }
                    }
                }
                propertyBuildingWindowSystems {
                    id
                    propertyBuildingWindowSystemComponents {
                      installationDate
                      installersName
                      condition
                      material
                      paneCount
                      style
                      brand
                      createdAt
                      isRented
                      id
                      kind
                      level
                      location
                      model
                      modelYear
                      name
                      notes
                      purchaseDate
                      purchasePrice
                      rentContractExpiry
                      rentContractLength
                      rentMonthlyAmount
                      retailerAddress
                      retailerEmail
                      retailerName
                      retailerPhone
                      serialNumber
                      updatedAt
                      warrantyExpiration
                      warrantyTerm
                      documents {
                        category
                        createdAt
                        desc
                        documentType
                        expiry
                        file {
                            updatedAt
                            size
                            metadata
                            id
                            filePath
                            fileName
                            createdAt
                            contentType
                        }
                        id
                        name
                        propertyBuildingAreaId
                        updatedAt
                        year
                      }
                      images {
                        contentType
                        createdAt
                        fileName
                        filePath
                        id
                        metadata
                        size
                        updatedAt
                      }
                    }
                }

            }
        }
    }  
`

// define Query for Validate Onboarding
export const VALIDATE_ONBOARDING = gql`
    query validateOnboarding {
        validateOnboarding{
            property {
                id
                addressId
              }
              isOnboarded
            }
        }
        
`

// define Query for Area Types
export const GET_AREA_TYPES = gql`
    query propertyBuildingAreaFields {
        propertyBuildingAreaFields {
            id
            interiorExterior
            name
        }
    }
`
// TODO:erase
// define Query for User Confirmation Token
export const GET_USER_CONFIRM_ACCOUNT = gql`
    query userConfirmAccount(
        $confirmationToken: String!
        $redirectUrl: String!
    ) {
        userConfirmAccount(
            confirmationToken: $confirmationToken
            redirectUrl: $redirectUrl
        ) {
            email
            phoneNumber
            firstName
            id
            lastName
        }
    }
`
// define Query for getting list of energy efficiency and remodelling updates
export const GET_UPDATES_LIST = gql`
    query propertyUpdatesFields {
        propertyUpdatesFields {
            id
            name
        }
    }
`
// define Query for getting list of goals
export const GET_GOALS_LIST = gql`
    query propertyGoalsFields {
        propertyGoalsFields {
            id
            name
        }
    }
`
// define Query to validate authentication
export const VALIDATE_AUTH = gql`
    {
        validateAuth
    }
`
// TODO: add wallPhotos and accentWallPhotos when backend implemented
export const ALL_PROPERTY_BUILDING_AREAS = gql`
    query ($propertyId: ID!){
        allPropertyBuildingAreas(id: $propertyId) {
            accentWallCoverBrand
            accentWallCoverCode
            accentWallCoverName
            accentWallFinish
            accentWallName
            accentWallNotes
            accentWallNumber
            accentWallPhotos {
                contentType
                fileName
                filePath
                id
                size
            }
            accentWallStatus
            accentWallType
            additionalPhotos {
                contentType
                fileName
                filePath
                id
                size
            }
            areaType
            category
            ceilingConditionStatus
            ceilingCoverBrand
            ceilingCoverCode
            ceilingCoverName
            ceilingFinishType
            ceilingNotes
            ceilingType
            description
            dimensions
            flooringConditionStatus
            createdAt
            flooringCoverCode
            flooringCoverManufacturer
            flooringHeatingType
            flooringIsHeated
            flooringName
            flooringNotes
            flooringSubType
            flooringType
            hasAccent
            id
            intExt
            length
            mainPhoto {
                contentType
                fileName
                filePath
                id
                size
            }
            name
            roomLevel
            status
            unit
            wallCoverBrand
            wallCoverCode
            wallCoverName
            wallFinish
            wallHeight
            wallName
            wallNotes
            wallPhotos {
                contentType
                fileName
                filePath
                id
                size
            }
            wallStatus
            wallType
            width
        }
    }
`

export const ALL_PROPERTY_DOCUMENTS = gql`
    query ($propertyId: ID!){
        allPropertyDocuments(id: $propertyId) {
            id
            name
            file {
                id
                fileName
                filePath
                filePathDirect
            }
            year
            expiry
            documentType
            propertyBuildingAreaId
            propertyBuildingAreaProjects{
                id
                projectType
            }
            createdAt
            updatedAt
            desc
            category
        }
    }
`

export const PROPERTY_DOCUMENT = gql`
    query ($id: ID!){
        propertyDocument(id: $id) {
            category
            createdAt
            desc
            documentType
            expiry
            propertyBuildingAreaId
            propertyBuildingAreaProjects{
                id
                projectType
            }
            id
            updatedAt
            name
            file {
                id
                fileName
            }
            year
        }
    }
`

export const ALL_PROPERTY_BUILDING_AREA_PROJECTS = gql`
    query ($id: ID!){
        allPropertyBuildingAreaProjects(id: $id) {
            id
            projectType
        }
    }
`

export const ALL_PROPERTIES = gql`
    {
        allProperties {
            fullAddress
            streetNo
            neighbourhood
            city
            province
            coordinates {
                lat
                lng
            }
            onboarded
            name
            userCoordinates{
                lat
                lng
            }
            acres
            id
            isCondo
            landDepth
            landFrontage
            landUnit
            addressId
            postalCode
            tempPostalCode
            desc
            title
            managerName
            managerPhoneNum
            managerAddress
            managmentCompany
            superintendantName
            superintendantPhoneNumber
            propertyBuildings{
                architecturalStyle
                attachment
                buildingType
                id
                styleOfDwelling
                typeOfDwelling
                frontDirection
                yearBuilt
                hasBasement
                ageStatus
                sqftTotal
                totalFloorArea
                totalFloorAreaUnit
                bedroomsAbove
                bedroomsBelow
                constructionType
                basementType
                basementFinishType
                basementDesc
                mainPhoto {
                    id
                    fileName
                }
                storeys
                additionalPhotos {
                    id
                    fileName
                }
                features
                buildingAmenities
                occupancyType
                bathroomsTotal
                fullBathrooms
                partialBathrooms
                bedroomsTotal
                status
                propertyBuildingParking {
                    additionalParking
                    age
                    colourCode
                    colourName
                    condition
                    conditionRating
                    description
                    drivewayMaterial
                    drivewaySpaces
                    drivewayWidth
                    garageFeatures
                    garageFloors
                    garageSpaces
                    garageType
                    id
                    insideEntry
                    instillationDate
                    manufacturer
                    purchaseDate
                    purchasePrice
                    status
                    serviceDate
                    warrantyExpiration
                    storage
                    warrantyTerm
                  }
                propertyBuildingAreas {
                    id
                    areaType
                    roomLevel
                    status
                    intExt
                    propertyBuildingAreaProjects{
                        id
                        projectType
                        finishDate
                        totalCosts
                    }
                }
            }
            propertyTaxInfo{
                id
                desc
                zoningDesc
                assesmentRoll
                zoningCode
                updatedAt
                createdAt
                amount
                assesmentAmount
            }
            propertyType
            propertyUsage {
                id
                ownershipStatus
                usageDesc
            }
            propertyGoals {
                id
                goal
            }
            propertyTransactions {
                id
                purchaseDate
                purchasePrice
            }
            propertyUpdates {
                efficiencyUpdates
                remodelingUpdates
                id
            }
        }
    }
`
// poll 'addressId' to avoid automatic cache updates due to data normalization (i.e. avoid premature sync of full property object with results from this poll, which would lead to sideEffects running before currentProperty is nullified at Lobby)
// i.e. if 'id' is polled instead, Apollo Client would automatically refetch any other query that also fetches objects with the same id, which would happen faster than the Lobby useEffects are able to run and nullify currentProperty, which leads to localStorage not being updated correctly at those sideEffects.
export const ALL_PROPERTIES_ADDRESS_IDS = gql`
    {
        allProperties {
            addressId
        }
    }
`

export const ALL_PROPERTY_INSURANCES = gql`
    query($id:ID!){
        allPropertyInsurances(id: $id) {
            advisorAddress
            advisorCompanyName
            advisorEmail
            advisorName
            advisorPhone
            advisorTollFreePhone
            advisorWebsite
            annualPolicyCost
            companyAddress
            companyClaimsPhone
            companyEmail
            companyName
            companyPhone
            companyTollFreePhone
            comprehensiveWater
            comprehensiveWaterForgiveness
            costOfRebuild
            coverageAmount
            coverageStartDate
            createdAt
            deductibleAmount
            discounts
            images {
                id
                fileName
                filePath
            }
            propertyDocuments{
                id
                name
                file {
                    id
                    fileName
                }
                year
                expiry
                documentType
                propertyBuildingAreaId
                propertyBuildingAreaProjects{
                    id
                    projectType
                }
                createdAt
                updatedAt
                desc
                category
            }
            exclusions
            id
            inclusions
            insuranceType
            paymentAmount
            notes
            paymentFrequency
            personalPropertyCoverage
            policyNum
            policyTerm
            renewalDate
            updatedAt
        }
    }
`

export const ALL_PROPERTY_INVENTORY_ITEMS = gql`
    query($property_id:ID!){
        allPropertyInventoryItems(id: $property_id) {
            age
            colourCode
            category
            featureType
            name
            colourName
            condition
            conditionRating
            createdAt
            description
            expectedLifespan
            financingAccountNumber
            financingCompanyName
            financingContractStartDate
            financingExpiry
            financingMonthlyAmount
            financingTerm
            id
            instillationDate
            isFinanced
            itemType
            manufacturer
            model
            ownershipType
            purchaseDate
            purchasePrice
            rentContractExpiry
            rentContractLength
            rentMonthlyAmount
            retailerAddress
            retailerEmail
            retailerName
            retailerPhone
            serialNumber
            serviceDate
            status
            surfaceFinish
            updatedAt
            warrantyExpiration
            warrantyTerm
            images {
                id
                fileName
                filePath
            }
            propertyBuildingAreas {
                id
                name
                areaType
            }
            areaName
            propertyInsurance {
                advisorAddress
                advisorCompanyName
                advisorEmail
                advisorName
                advisorPhone
                advisorTollFreePhone
                advisorWebsite
                annualPolicyCost
                companyAddress
                companyClaimsPhone
                companyEmail
                companyName
                companyPhone
                companyTollFreePhone
                comprehensiveWater
                comprehensiveWaterForgiveness
                costOfRebuild
                coverageAmount
                coverageStartDate
                createdAt
                deductibleAmount
                discounts
                documentUrl
                exclusions
                id
                images {
                    contentType
                    createdAt
                    fileName
                    filePath
                    id
                    metadata
                    size
                    updatedAt
                }
                inclusions
                insuranceType
                notes
                paymentAmount
                paymentFrequency
                personalPropertyCoverage
                policyNum
                policyTerm
                propertyDocuments {
                    category
                    createdAt
                    desc
                    documentType
                    expiry
                    file {
                        contentType
                        createdAt
                        fileName
                        filePath
                        id
                        metadata
                        size
                        updatedAt
                    }
                    id
                    name
                    updatedAt
                    year
                }
                renewalDate
                updatedAt
            }
            propertyDocuments{
                id
                name
                file {
                    id
                    fileName
                }
                year
                expiry
                documentType
                propertyBuildingAreaId
                propertyBuildingAreaProjects{
                    id
                    projectType
                }
                createdAt
                updatedAt
                desc
                category
            }
        }
    }
`

