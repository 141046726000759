import App from './App'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    HttpLink,
    from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from "@apollo/client/link/retry"

// This components initializes the Apollo Client and is never re-rendered, so that the client cache persists.
export default function Client() {

    // What to do if there is any error
    const errorLink =  onError(({ operation, graphQLErrors, networkError, forward }) => {
        if (graphQLErrors) {
            const locci = window.location.pathname
            if(locci.includes('login')){ return } // do not log validation errors at login screen

            graphQLErrors.forEach(({ message, locations, path }, idx) =>
                console.error(`Graphql Error ${idx}: Message: ${message}, Location: ${locations}, Path: ${path}, locci: ${locci}`)
            )
        }
        if (networkError) {
            // console.error(`[Network Error] message: ${networkError?.message}, stack: ${networkError?.stack} `)
        }
    })
    // Authorization link: fetches credentials for every request to the server (i.e. updates auth headers)
    const authLink = setContext((_, { headers }) => {
        // get the authentication credentials from local storage if it exists
        const credentials = JSON.parse(localStorage.getItem('credentials'));
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            'access-token': credentials ? credentials.accessToken : '',
            client: credentials ? credentials.client : '',
            expiry: credentials ? credentials.expiry : '',
            'token-type': credentials ? credentials.tokenType : '',
            uid: credentials ? credentials.uid : '',
          }
        }
    })
    // handle transient network errors:
    const retryLink = new RetryLink({// default config made explicit for maintenance purposes
        delay: {
          initial: 300,
          max: Infinity,
          jitter: true
        },
        attempts: {
          max: 5,
          retryIf: (error, _operation) => !!error // retries if error is truthy
        }
      })

    // request (terminating) link
    const httpLink = new HttpLink({
        uri: process.env.REACT_APP_APOLLO_URI,
    })
    
    const mergeWithoutDuplicates = (existing = [], incoming) =>{
        const merged = new Map(existing.map(item => [item.__ref, item]))
        for (const item of incoming){
            merged.set(item.__ref, item)
        }
        return Array.from(merged.values())
    }

    // Initialize ApolloClient passing a configuration object to its constructor
    const client = new ApolloClient({
        link: from([errorLink, authLink, retryLink, httpLink]),
        cache: new InMemoryCache({
            addTypename: true,
            //defining merge function for basementDelete() mutation which deletes propertyBuildingAreas
            // This is to avoid a warning message related to Apollo cache
            typePolicies: {
                Property:{
                    fields:{
                        // Custom merge function for the entire 'Property' object
                        merge(existing= {}, incoming){
                            //preserve existing, and add incomming, so that different queries for different property fields do not delete other fields in the property object.
                            return {...existing, ...incoming}
                        },
                        //specific merge strategy for 'propertyGoals' field
                        propertyGoals:{
                            merge(existing, incoming){
                                return incoming // Always use the incoming data in this case
                            }
                        },
                        propertyInsurances: {
                            merge(existing, incoming){
                                return incoming // Always use the incoming data in this case
                            }
                        }
                    }
                },
                PropertyBuilding:{
                    fields:{
                      additionalPhotos: {
                        merge(existing, incoming){
                          return incoming // Always use the incoming data in this case
                        }
                      },
                      propertyBuildingAreas:{
                        merge(existing, incoming){
                          return incoming // Always use the incoming data in this case
                        }
                      }
                    }
                },
                propertyBuildingArea:{
                  fields:{
                    additionalPhotos:{
                      merge(existing, incoming){
                        return incoming // Always use the incoming data in this case
                      }
                    },
                    wallPhotos:{
                      merge(existing, incoming){
                        return incoming // Always use the incoming data in this case
                      }
                    },
                    accentWallPhotos:{
                      merge(existing, incoming){
                        return incoming // Always use the incoming data in this case
                      }
                    },
                  },
                },
                PropertyInsurance: {
                  fields: {
                    propertyDocuments: {
                      merge(existing =[], incoming=[]){
                        return incoming
                      }
                    }
                  }
                },
                PropertyInventoryItem: {
                  fields: {
                    propertyDocuments: {
                      merge( existing=[], incoming=[]){
                        return incoming
                      }
                    }
                  }
                },
                PropertyBuildingHeatingSystemComponent: {
                    fields: {
                      images: {
                        merge: mergeWithoutDuplicates,
                      },
                      documents:{
                        merge(existing=[], incoming=[]){
                          return incoming //always use the incoming data in this case   
                        }
                      }
                    }
                },
                PropertyBuildingCoolingSystemComponent: {
                    fields: {
                      images: {
                        merge: mergeWithoutDuplicates,
                      },
                      documents:{
                        merge(existing=[], incoming=[]){
                          return incoming //always use the incoming data in this case   
                        }
                      }
                    }
                },
                PropertyBuildingElectricalSystemComponent: {
                    fields: {
                      images: {
                        merge: mergeWithoutDuplicates,
                      },
                      documents:{
                        merge(existing=[], incoming=[]){
                          return incoming //always use the incoming data in this case   
                        }
                      }
                    }
                },
                PropertyBuildingWasteSystemComponent: {
                    fields: {
                      images: {
                        merge: mergeWithoutDuplicates,
                      },
                      documents:{
                        merge(existing=[], incoming=[]){
                          return incoming //always use the incoming data in this case   
                        }
                      }
                    }
                },
                PropertyBuildingWaterSystemComponent: {
                    fields: {
                      images: {
                        merge: mergeWithoutDuplicates,
                      },
                      documents:{
                        merge(existing=[], incoming=[]){
                          return incoming //always use the incoming data in this case   
                        }
                      }
                    }
                },
                PropertyBuildingRoofSystemComponent: {
                    fields: {
                      images: {
                        merge: mergeWithoutDuplicates,
                      },
                      documents:{
                        merge(existing=[], incoming=[]){
                          return incoming //always use the incoming data in this case   
                        }
                      }
                    }
                },
                PropertyBuildingExtsidSystemComponent: {
                    fields: {
                      images: {
                        merge: mergeWithoutDuplicates,
                      },
                      documents:{
                        merge(existing=[], incoming=[]){
                          return incoming //always use the incoming data in this case   
                        }
                      }
                    }
                },
                PropertyBuildingExtdrSystemComponent: {
                    fields: {
                      images: {
                        merge: mergeWithoutDuplicates,
                      },
                      documents:{
                        merge(existing=[], incoming=[]){
                          return incoming //always use the incoming data in this case   
                        }
                      }
                    }
                },
                PropertyBuildingWindowSystemComponent: {
                    fields: {
                      images: {
                        merge: mergeWithoutDuplicates,
                      },
                      documents:{
                        merge(existing=[], incoming=[]){
                          return incoming //always use the incoming data in this case   
                        }
                      }
                    }
                },
                PropertyBuildingFoundationSystemComponent: {
                    fields: {
                      images: {
                        merge: mergeWithoutDuplicates,
                      },
                      documents:{
                        merge(existing=[], incoming=[]){
                          return incoming //always use the incoming data in this case   
                        }
                      }
                    }
                },
                Query: {
                    fields: {
                        allPropertyBuildingAreas: {
                            merge(existing, incoming){
                                 return incoming // Always use the incoming data in this case
                            }
                        },

                        // allProperties: {
                        //     merge( existing, incoming){
                        //         return incoming // Always use the incoming data in this case
                        //     }
                        // }

                    }
                }
            }
        }),
        credentials: 'include',
        connectToDevTools: true,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-first',
                nextFetchPolicy: 'cache-and-network',
            },
          },
    })
  

  return (
    <ApolloProvider client={client}>
        <App/>
    </ApolloProvider>
  )
}
