import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from "@sentry/react"
import { BrowserTracing } from '@sentry/tracing'
import './index.css'
import Client from './Client'
import reportWebVitals from './reportWebVitals'

// Initialize Sentry to capture errors
Sentry.init({
    dsn:"https://389754f7272f44f4b3627fab4cdbd1e3@o4504209219715072.ingest.sentry.io/4504215058186240",
    integrations: [ new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    
        <React.StrictMode>
            <Sentry.ErrorBoundary fallback={<p> An error has ocurred </p>}>
                <Client />
            </Sentry.ErrorBoundary>
        </React.StrictMode>
    
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
