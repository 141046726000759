import React from 'react'
import { useNavigate } from 'react-router-dom'
import errorImage from '../Images/Homeprint-login.jpeg'

// Error Code 404
function ErrorScreen(props) {
    const navigate = useNavigate()
    return (
            <div className="font-int flex  min-h-screen flex-col bg-white lg:relative">
                {/* container of body and image */}
                <div className="flex w-full max-w-[1536px] h-full grow flex-col justify-center items-center lg:flex-row lg:items-stretch 2xl:mx-auto">
                    {/* container for vertical alignment and large screens in 2 */}
                    <div className="lg:w-1/2 lg:my-auto">
                        {/* container for horizontal alignment of text elements */}
                        <div className="mx-3 flex-shrink-0 flex-wrap py-12 sm:mx-12 sm:max-w-2xl sm:pt-32 sm:pb-28 lg:w-3/4 lg:py-16 xl:w-2/3">
                            {/* text */}
                            <p className="inline-block rounded-full border-2 border-solid border-[#A97575] bg-red-200 px-4 py-1 text-lg font-semibold text-[#666666]">
                                {props.code}
                            </p>
                            <h1 className="my-6 text-6xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:tracking-tight">
                                Oh no!
                            </h1>
                            <p className="mt-2 text-base text-gray-500">
                                It looks like you’ve reached
                                a URL that doesn’t exist. Please use the
                                navigation above or click below to find your way
                                back home to our website.
                            </p>
                            {/* Button's container */}
                            <div className="mt-8 flex flex-col sm:flex-row">
                                {/* 'Go back' button */}
                                {/* TODO: include left arrow icon */}
                                <button
                                    onClick={() => navigate(-1)}
                                    className="mb-3 btn-secondary-lg sm:mb-0 sm:mr-3"
                                >
                                    Go back
                                </button>
                                {/* 'Take me home' button */}
                                <a
                                    href="/"
                                    className="btn-primary-lg"
                                >
                                    Take me home
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* photo */}
                    <div className="max-lg:hidden grow min-h-[350px] w-full mx-3 lg:w-1/2 bg-cover bg-no-repeat bg-right lg:mx-0"
                    style={{
                        backgroundImage: `url(${errorImage})`
                    }}>
                        {/* <img
                            className="h-full object-cover lg:object-right"
                            src={errorImage}
                            alt="A woman standing by her office desk and looking at the camera"
                        /> */}
                    </div>
                </div>
            </div>
    )
}

export default ErrorScreen
