import React from 'react'
import InputMask from "react-input-mask"

export default function PhoneInput(props) {
  return (
    <InputMask 
        mask="(999) 999 9999"
        className={props.className}
        onChange={props.onChange}
        value={props.value}
        {...props}        
    />
  )
}

